import { 
	IonPage, 
	IonHeader, 
	IonToolbar, 
	IonTitle, 
	IonContent, 
	toastController,
	modalController, 
	IonItem,
	IonSelectOption,
	IonList,
	IonSelect,
	IonItemDivider,
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonRippleEffect,
	IonImg,
	IonThumbnail,
} from '@ionic/vue';
import { IonButtons, IonMenuButton } from '@ionic/vue';

import SubCategoriaModal from '../../modals/subcategorias/index.vue';
import { 
  exitOutline,
  cogOutline,
} from 'ionicons/icons';
import ExploreContainer from '@/components/ExploreContainer.vue';

export default  {
	name: 'configurar',
		components: { 
		ExploreContainer, 
		IonHeader, 
		IonToolbar, 
		IonTitle, 
		IonContent, 
		IonButtons,
		IonMenuButton,
		IonSelectOption,
		IonSelect,
		IonRow,
	    IonCol,
		IonButton,
		IonIcon,
		IonPage,
		IonList,
		IonItem,
		IonItemDivider,
		IonLabel,
		IonRippleEffect,
		IonImg,
		IonThumbnail,
	},
	setup() {
		return {
			exitOutline,
			cogOutline
		}
	},
	data () {
		return {
			buscar: {bloque:3},
			status: {},
			errors: {},
			servicios: {},
			categorias: {},
			subcategorias: {},
			cantidad: {},
			cantidadServicios: {},
			tipoBloques:{
				"todos":{
					id:3,
					nombre:"Todos"
				},
				"manana":{
					id:2,
					nombre:"Mañana"
				},
				"tarde":{
					id:1,
					nombre:"Tarde"
				}
			},
			
		}
	},
	mounted() {

		



		this.getCategorias();
		console.log("buscar");
		console.log(this.buscar);
		console.log("buscar");
	},
  	methods: {
		getSubCategorias(categoria){
			var formData = new window.FormData();
			
			formData.append(
			  "id_padre",
			  categoria.id
			);
			formData.append(
				"bloque",
				this.buscar.bloque
			  );
			formData.append(
				'usuario',
				window.usuario_id
			);
			window.http.post(
			  process.env.VUE_APP_URL + "/api/subcategorias", 
			  formData,
			  window.headers
			).then(
			  res => {
				console.log(res );
				this.subcategorias = res.data.categorias
				this.servicios = res.data.servicios
				this.cantidadServicios = res.data.cantidad
				this.proveedores = res.data.prestadores
				console.log('[PAGE CATEGORIAS] servicios')
				console.log(this.cantidadServicios)
				console.log('[PAGE CATEGORIAS] servicios')
				this.ModalSubCategoria(this.subcategorias,this.servicios,this.cantidadServicios,categoria)
				
			  }
			).catch(
			  err => {
				if (err.status === 500) {
				  this.status = err.data.status
				}
	
				if (err.status === 422) {
				  this.errors = err.data.errors
				}
	
				if (err.status === 401) {
				  this.errors = err.data.errors
				}
				console.log(err)
			  }
			);
		},Filtrar(e){
			e.preventDefault();
			var formData = new window.FormData();

			formData.append(
				"bloque",
				this.buscar.bloque
			  );
			
			window.http.post(
				process.env.VUE_APP_URL + "/api/categorias", 
				formData,
				window.headers
			).then(
				res => {
					this.categorias = res.data.categorias
					
					this.cantidad = res.data.cantidad	
					console.log("categoriasssss")
					console.log(res.data.categorias)
					console.log("categoriasssss")				
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},BorrarFiltro(e){
			e.preventDefault();
			var formData = new window.FormData();

			this.buscar.bloque =
			formData.append(
				"bloque",
				3
			  );
			
			window.http.post(
				process.env.VUE_APP_URL + "/api/categorias", 
				formData,
				window.headers
			).then(
				res => {
					this.categorias = res.data.categorias
					
					this.cantidad = res.data.cantidad	
					console.log("categoriasssss")
					console.log(res.data.categorias)
					console.log("categoriasssss")				
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},
		async ModalSubCategoria(subcategorias,servicios,cantidadServicios,categoria) {
			console.log('[PAGE CATEOGIAS] modal subcateogiras')
		  	console.log(subcategorias)
			console.log('[PAGE CATEOGIAS] modal subcateogiras')
		const modal = await modalController
		  .create({
			component: SubCategoriaModal,
			componentProps: {
			  title: 'New Title',
			  subcategorias: subcategorias,
			  servicios: servicios,
			  cantidadServicios: cantidadServicios,
			  categoria: categoria,
			  proveedores: this.proveedores,
			  bloque: this.buscar.bloque,
			}
		  })
		return modal.present();
	  },
  		async openToast(msg) {
	        const toast = await toastController.create({
	          message: msg,
	          duration: 2000,
	          position: 'top',
	        })
	        return toast.present();
	    },

  		getCategorias() {
			
			var formData = new window.FormData();
			window.http.get(
				process.env.VUE_APP_URL + "/api/categorias", 
				formData,
				window.headers
			).then(
				res => {
					console.log(res);
					this.categorias = res.data.categorias
					this.cantidad = res.data.cantidad		
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},
 
		Logout(e) {
			e.preventDefault();
			var formData = new window.FormData();
			window.http.post(
				process.env.VUE_APP_URL + "/api/logout", 
				formData,
				window.headers
			).then(
				res => {
					console.log(res)
					if (res.data.exito == true) {
						localStorage.removeItem('_cap_tok_code');
						localStorage.removeItem('_cap_usuario_id');
						localStorage.removeItem('_cap_rol');
						this.$router.replace({ path: '/inicio' })
					} else {
						this.openToast(res.data.msg)
					}
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		}
	}
};