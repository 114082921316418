import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent, 
  IonList, 
  IonInput, 
  IonItem, 
  IonCol, 
  IonRow, 
  IonButton,
  IonCardContent,
  IonCard,
  IonToggle,
  IonToast, 
  toastController,
} from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';

export default  {
  name: 'recuperar',
  components: { 
    ExploreContainer, 
    IonToast,
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent, 
    IonPage,
    IonList,
    IonInput,
    IonItem,
    IonCol,
    IonButton,
    IonRow,
    IonCardContent,
    IonCard,
    IonToggle
  },
  props: [],
  data () {
    return {
      username: "",
      // password: "",
      // persona_contacto: "",
      // empresa: "",
      // rut: "",
      // telefono: "",
      // comuna: "",
      // direccion: "",
      // submitted: false,
      // flagform_log: 0,
      // flagform_reg: false
      flagform_log: 0,
			flagform_reg: false,
			status: {},
			errors: {},
			msg_error: ""
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    beforeTabChange() {
      alert("despues")
    },

    afterTabChange() {
      
    },

    submitForm() {

    },



    usernameValid() {
      return true;
    },
    passwordValid() {
      return true;
    },
    onLogin(ev) {
      ev.preventDefault();
    },

    CambioForm(op) {
      this.flagform_log = op
    },


    async ModalTerminos() {
      const modal = await this.modal
        .create({
          component: Modal,
          cssClass: 'my-custom-class',
          componentProps: {
            title: 'New Title'
          },
        })
      return modal.present();
    },



    RecuperarContrasena(e) {

      e.preventDefault();
      let email = document.getElementById("username").value;

      window.http.get(
        process.env.VUE_APP_URL + "/api/forgot-password/" + email
      );
    },
    
    async openToast() {
      const toast = await toastController
        .create({
          message: 'Si el correo electrónico existe en nuestro sistema, te enviaremos las instrucciones para recuperar tu contraseña.',
          duration: 4000,
          position: 'top',

        })
      return toast.present();
    },

  }
}



