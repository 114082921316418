import ExploreContainer from '@/components/ExploreContainer.vue';
import { 
	IonContent, 
	IonHeader, 
	IonTitle, 
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	modalController,
	IonMenuButton, 
	IonInput,
	IonPage,  
	IonItemDivider,
	IonLabel,
	IonToggle,
	IonRippleEffect,
	IonImg,
	toastController,
	IonList,
	IonSelect,
	IonDatetime,
	IonItem,
	IonSelectOption,
} from '@ionic/vue';
import { Terminos } from 'vue';
import UnServicioModal from '../../modals/servicio/index.vue';
import { 
  exitOutline,
  chevronBackOutline,
  checkmark,
  ban
} from 'ionicons/icons';
export default {
  name: 'servicios-modal',
  components: { 
  	IonContent, 
  	IonHeader, 
  	IonTitle, 
  	IonToolbar,
  	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	ExploreContainer, 
	IonMenuButton,
	IonInput,
	IonToggle,
	IonDatetime,
	IonPage,
	IonList,
	IonImg,
	IonRippleEffect,
	IonItemDivider,
	IonLabel,
	IonItem,
	IonSelect,
	IonSelectOption,
  },
  props: {
    title: { 
    	type: String, 
    	default: 'Super Modal' 
    },
    editarServicio: {
      type: Object,
      default: {}
    },
	categorias:{
		type: Object,
		default: {}
	},
	tipos_servicios: {
		type: Object,
		default: {}
	},
	estados_servicio: {
		type: Object,
		default: {}
	},
	zonas: {
		type: Object,
		default: {}
	},
	subzonas: {
		type: Object,
		default: {}
	},
	kilos: {
		type: Object,
		default: {}
	},
	marcas: {
		type: Object,
		default: {}
	},
	razas: {
		type: Object,
		default: {}
	},
	categoriaComida:{
		type: Object,
		default: {}
	}

  },
  setup() {
  	return {
  		chevronBackOutline,
		checkmark,
		ban
    };
  },
  data() {
    return {
		servicio: {
			nombre: "",
			lunes: true,
			martes: true,
			miercoles: true,
			jueves: true,
			viernes: true,
			sabado: true,
			domingo: true
		}
    }
  },
  mounted() {
	
	console.log('[MODAL SERVICIO ENTRANDO A LA VISTA ] formulario servicio')
	console.log(this.editarServicio)
  },
  methods: {
  	Back() {
  		modalController.dismiss();
  	},
	

	ConvertBoolToInt(data) {
		if (data == true) {
			return 1;
		} else {
			return 0;
		}
	},async openToast(msg) {
		const toast = await toastController.create({
			message: msg,
			duration: 2000,
			position: 'top',
		})
		return toast.present();
	},
	ObtenerComunas(select) {
		var formData = new window.FormData();
		this.editarServicio.subzona_id = "";
		window.http.get(
			process.env.VUE_APP_URL + "/api/subzonas/getbyzona/" + select.target.value,
			formData,
			window.headers
		).then(
			res => {
				this.subzonas = res.data
				console.log(res.data)
			}
		).catch(
			err => {
				if (err.status === 500) {
					this.status = err.data.status
				}

				if (err.status === 422) {
					this.errors = err.data.errors
				}

				if (err.status === 401) {
					this.errors = err.data.errors
				}
				console.log(err)
			}
		);
	},
	ConfigurarServicio(e) {
		e.preventDefault();
		// if (this.editarServicio.tipo_servicio_id == null) {
		// 	this.openToast("El campo tipo servicio es requerido");
		// } 
		console.log("fecha -->"+this.editarServicio.jornada_inicio)
		if (this.editarServicio.categoria_id == null) {
			this.openToast("El campo categoria es requerido");
		} else if (this.editarServicio.subzona_id == null) {
			this.openToast("Las subzonas son requeridas");
		}else if (this.editarServicio.zona_id == null) {
			this.openToast("Las zonas son requeridas");
		} else if (this.editarServicio.valor == null) {
			this.openToast("El campo valor es requerido");
		} else if (this.editarServicio.estado_id == null) {
			this.openToast("El campo estado es requerido");
		} else if (this.editarServicio.jornada_inicio == null) {
			this.openToast("El campo jornada inicio es requerido");
		} else if (this.editarServicio.jornada_fin == null) {
			this.openToast("El campo jornada final es requerido");
		} else if (this.editarServicio.descripcion == null) {
			this.openToast("El campo descripcion es requerido");
		} else if (this.editarServicio.manana == false && this.editarServicio.tarde == false) {
			this.openToast("Debes seleccionar un bloque de horario");
		}else {
			var formData = new window.FormData();

			let errores = false;
			let mensaje = "";
			if (this.editarServicio.categoria_id == this.categoriaComida){
				if(this.editarServicio.comidadeperro.marca_id == null){
					errores = true;
					mensaje = "El campo marca es requerido";
				}else if (this.editarServicio.comidadeperro.kilos_id == null){
					errores = true;
					mensaje = "El campo kilo es requerido";
				}else if (this.editarServicio.comidadeperro.raza_id == null){
					errores = true;
					mensaje = "El campo raza es requerido";
				}
			}
			if(errores ){
				this.openToast(mensaje);
			}else{
				if (this.editarServicio.categoria_id == this.categoriaComida){

					formData.append(
						'marca',
						this.editarServicio.comidadeperro.marca_id
					);
					formData.append(
						'kilo',
						this.editarServicio.comidadeperro.kilos_id
					);
					formData.append(
						'raza',
						this.editarServicio.comidadeperro.raza_id
					);
				}

				formData.append(
					'servicio_id', 
					this.editarServicio.id
				);
				formData.append(
					'categoria_id', 
					this.editarServicio.categoria_id
				);
				formData.append(
					'subzona_id', 
					this.editarServicio.subzona_id
				);
				formData.append(
					'zona_id', 
					this.editarServicio.zona_id
				);
				formData.append(
					'descripcion', 
					this.editarServicio.descripcion
				);
				formData.append(
					'estado_id', 
					this.editarServicio.estado_id
				);
				formData.append(
					'nombre', 
					this.editarServicio.nombre
				);
				formData.append(
					'tipo_servicio_id', 
					1
				);
				formData.append(
					'usuario_id', 
					window.usuario_id
				);
				formData.append(
					'valor', 
					parseInt(this.editarServicio.valor, 10)
				);
	
			
				formData.append(
					'jornada_inicio', 
					this.editarServicio.jornada_inicio
				);
	
				formData.append(
					'jornada_fin', 
					this.editarServicio.jornada_fin
				);
	
				formData.append(
					'manana', 
					this.ConvertBoolToInt(this.editarServicio.manana)
				);
				formData.append(
					'tarde', 
					this.ConvertBoolToInt(this.editarServicio.tarde)
				);
				formData.append(
					'lunes', 
					this.ConvertBoolToInt(this.editarServicio.lunes)
				);
				formData.append(
					'martes', 
					this.ConvertBoolToInt(this.editarServicio.martes)
				);
				formData.append(
					'miercoles', 
					this.ConvertBoolToInt(this.editarServicio.miercoles)
				);
				formData.append(
					'jueves', 
					this.ConvertBoolToInt(this.editarServicio.jueves)
				);
				formData.append(
					'viernes', 
					this.ConvertBoolToInt(this.editarServicio.viernes)
				);
				formData.append(
					'sabado', 
					this.ConvertBoolToInt(this.editarServicio.sabado)
				);
				formData.append(
					'domingo', 
					this.ConvertBoolToInt(this.editarServicio.domingo)
				);
	
	
				window.http.post(
					process.env.VUE_APP_URL + "/api/servicios/editar", 
					formData,
					window.headers
				).then(
					res => {
						console.log(res)
						if (res.data.exito == true) {
							this.openToast(res.data.msg);
							this.Back()
						} else {
							this.openToast("Ha ocurrido un error, intentelo mas tarde");
						}
						this.servicio =  {
							nombre: "",
							lunes: false,
							martes: false,
							miercoles: false,
							jueves: false,
							viernes: false,
							sabado: false,
							domingo: false
						};
	
					}
				).catch(
					err => {
						if (err.status === 500) {
							this.status = err.data.status
						}
	
						if (err.status === 422) {
							this.errors = err.data.errors
						}
	
						if (err.status === 401) {
							this.errors = err.data.errors
						}
						console.log(err)
					}
				);
			}
		}
		console.log(this.servicio)
	},
  }
};