import ExploreContainer from '@/components/ExploreContainer.vue';
import SolicitudModal from '../../modals/solicitudes/index.vue';
import { 
	IonContent, 
	IonHeader, 
	IonTitle, 
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	modalController,
	IonMenuButton, 
	IonInput,
	IonPage,  
	IonItemDivider,
	IonToggle,
	IonLabel,
	IonRippleEffect,
	IonImg,
	toastController,
	IonList,
	IonSelect,
	IonDatetime,
	IonItem,
	IonSelectOption,
} from '@ionic/vue';
import { Terminos } from 'vue';

import eventBus from '../../../eventBus.js';

import { 
	person,
	call,
	pin,
	flagOutline,
	exitOutline,
	flag,
	chevronBackOutline,
	checkmark,
	ban
} from 'ionicons/icons';
export default {
  name: 'servicios-modal',
  components: { 
  	IonContent, 
  	IonHeader, 
  	IonTitle, 
  	IonToolbar,
  	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	ExploreContainer, 
	IonPage,
	IonMenuButton,
	IonInput,
	IonItemDivider,
	IonToggle,
	IonLabel,
	IonRippleEffect,
	IonImg,
	IonList,
	IonSelect,
	IonDatetime,
	IonItem,
	IonSelectOption,
  },
  props: {
    solicitud: {
      type: Object,
      default: {}
    },
	servicio: {
		type: Object,
      default: {}
	},
	valoracion_cliente: {
		type: Object,
      default: {}
	},
	terminada: {
		type: Object,
		default: {}
	}
  },
  setup() {
  	return {
		person,
		call,
		pin,
		flagOutline,
		flag,
  		chevronBackOutline,
		checkmark,
		ban
    };
  },
  data() {
    return {
		estados_solitudes: {},
		estrella: {},
		terminada: false,
		mostrarEstrellas: false,
		colorBackground: {},
    }
  },
  mounted() {
	this.ObtenerEstadoSolicitud()
	console.log('[MODAL SOLICITUDES] this solicitud-serviio')
    console.log(this.solicitud)
	console.log('[MODAL SOLICITUDES] this solicitud-serviio')
	console.log('[MODAL SERVICIOS] this solicitud-serviio')
	console.log(this.valoracion_cliente)
	console.log('[MODAL SERVICIOS] this solicitud-serviio')

	if(this.valoracion_cliente == 0 ){
		this.mostrarEstrellas = true;
	}
	if(this.solicitud.estado.id == 3){
		this.terminada = true;
	}

	if(this.solicitud.estado.id == 1){
		this.colorBackground = {
			'background-color': '#4DBFAD',
		}
	}else if(this.solicitud.estado.id == 2){
		this.colorBackground = {
			'background-color': '#FFDAA3',
		}
	}else{
		this.colorBackground = {
			'background-color': '#F27B6F',
		}
	}

	console.log(this.colorBackground)


  },
  methods: {
  	Back() {
  		modalController.dismiss();
  	},async openToast(msg) {
		const toast = await toastController.create({
			message: msg,
			duration: 2000,
			position: 'top',
		})
		return toast.present();
	},
	ObtenerEstadoSolicitud() {
		  var formData = new window.FormData();
		window.http.get(
			process.env.VUE_APP_URL + "/api/solicitud/estados", 
			formData,
			window.headers
		).then(
			res => {
				console.log("[MODAL SOLICITUDES] obtener tipos servicios")
				this.estados_solitudes = res.data
				console.log(this.estados_solitudes)
				console.log("[MODAL SOLICITUDES] obtener tipos servicios")
			}
		).catch(
			err => {
				if (err.status === 500) {
					this.status = err.data.status
				}

				if (err.status === 422) {
					this.errors = err.data.errors
				}

				if (err.status === 401) {
					this.errors = err.data.errors
				}
				console.log(err)
			}
		);
	  },
		async ModalDetalleSolicitud(servicio,solicitud) {
			const modal = await modalController
				.create({
					component: SolicitudModal,
					componentProps: {
						servicio:servicio,
						solicitud: solicitud,
						terminada: true,
						valoracion_cliente: 0
					}
				})
			return modal.present();
		},
	  ConfigurarSolicitudEstado(e) {
		e.preventDefault();

		
			var formData = new window.FormData();
			
			formData.append(
				'estado_id', 
				3
			);
			formData.append(
				'solicitud_id', 
				this.solicitud.id
			);

			window.http.post(
				process.env.VUE_APP_URL + "/api/solicitud/cambio-estado", 
				formData,
				window.headers
			).then(
				res => {
					if (res.data.exito == true) {
						this.openToast('Cambio de estado exitoso!');
					} else {
						this.openToast('Cambio de estado exitoso!');
					}
					this.solicitud.estado.id = 3;
					this.solicitud.estado.nombre = "Terminada";
					this.ModalDetalleSolicitud(this.servicio,this.solicitud);
					this.Back()
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},
		ConfigurarSolicitudEstadoInicio(e) {
			e.preventDefault();
	
			
				var formData = new window.FormData();
				
				formData.append(
					'estado_id', 
					2
				);
				formData.append(
					'solicitud_id', 
					this.solicitud.id
				);
				window.http.post(
					process.env.VUE_APP_URL + "/api/solicitud/cambio-estado", 
					formData,
					window.headers
				).then(
					res => {
						if (res.data.exito == true) {
							this.openToast('Cambio de estado exitoso!');
						} else {
							this.openToast('Cambio de estado exitoso!');
						}
						this.Back()
					}
				).catch(
					err => {
						if (err.status === 500) {
							this.status = err.data.status
						}
	
						if (err.status === 422) {
							this.errors = err.data.errors
						}
	
						if (err.status === 401) {
							this.errors = err.data.errors
						}
						console.log(err)
					}
				);
			},
		Calificar(e){
			e.preventDefault();
			var formData = new window.FormData();
			console.log(this.estrella.calificacion_cliente)
			if (!this.estrella.calificacion_cliente) {
				this.openToast("Selecciona una clasificacion de para el cliente");
			}else{
				
				formData.append(
					'solicitud_id', 
					this.solicitud.id
				);
				formData.append(
					'calificacion', 
					this.estrella.calificacion_cliente
				);
				formData.append(
					'comentario', 
					this.estrella.comentarios
				);
				formData.append(
					'usuario_id', 
					this.servicio.usuario_id
				);
				formData.append(
					'proveedor_id', 
					this.solicitud.usuario_id
				);
				
				window.http.post(
					process.env.VUE_APP_URL + "/api/valoraciones/cliente", 
					formData,
					window.headers
				).then(
					res => {
						if (res.data.exito == true) {
							this.openToast('Valoración ingresada con exito!');
							eventBus.$emit('update-solicitudes-realizadas-proveedor');
						} else {
							this.openToast('Valoración ingresada con exito!');
						}
						this.Back()
					}
				).catch(
					err => {
						if (err.status === 500) {
							this.status = err.data.status
						}
	
						if (err.status === 422) {
							this.errors = err.data.errors
						}
	
						if (err.status === 401) {
							this.errors = err.data.errors
						}
						console.log(err)
					}
				);
			}

		},
		Llegue(e){
			e.preventDefault();
			var formData = new window.FormData();
				
			formData.append(
				'usuario_id', 
				this.servicio.usuario_id
			);
			formData.append(
				'solicitud_id', 
				this.solicitud.id
			);
			
			window.http.post(
				process.env.VUE_APP_URL + "/api/solicitudes/llegue", 
				formData,
				window.headers
			).then(
				res => {
					if (res.data.exito == true) {
						this.openToast('Notificación enviada con exito!');
					} else {
						this.openToast('Notificación enviada con exito!');
					}
					this.Back()
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					this.Back();
				}
			);
			

		}
  }
};