import ExploreContainer from '@/components/ExploreContainer.vue';
import { 
  IonButtons, 
  IonMenuButton, 
  IonInput,
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonCard,
  IonItemDivider,
  IonToggle,
  IonRow,
  IonCol,
  IonLabel,
  IonRippleEffect,
  IonIcon,
  IonButton,
  IonImg,
  toastController,
  IonList,
  IonSelect,
  IonDatetime,
  IonItem,
  IonSelectOption,

} from '@ionic/vue';


import { 
	camera, 
	trash, 
	close,
	personCircleOutline,
	exitOutline,
			chevronBackOutline 
} from 'ionicons/icons';
import { usePhotoGallery, Photo } from '../../../_helpers/usePhotoGallery.js';
import { ref, onMounted, watch } from 'vue';
import { 
	Plugins, 
	CameraResultType, 
	CameraSource, 
	CameraPhoto, 
	Capacitor, 
	FilesystemDirectory 
} from "@capacitor/core";


const { Camera } = Plugins;

export default  {
	name: 'perfiles',
	components: { 
		ExploreContainer, 
		IonHeader, 
		IonToolbar, 
		IonTitle, 
		IonContent, 
		IonPage,
		IonButtons,
		IonMenuButton,
		IonInput,
		IonCard,
		IonItemDivider,
		IonToggle,
		IonRow,
  		IonCol,
  		IonLabel,
  		IonRippleEffect,
  		IonIcon,
  		IonButton,
  		IonImg,
  		IonList,
  		IonSelect,
  		IonDatetime,
  		IonItem,
  		IonSelectOption,

	},
	setup() {
		var { 
			takePhoto, 
			photos, 
			showActionSheet 
		} = usePhotoGallery();
	
    	return {
			takePhoto, 
			photos, 
			personCircleOutline,
			showActionSheet,
			camera, 
			trash, 
			close,
			exitOutline,
			chevronBackOutline
		}
	},
	data () {
		return {
			direccion: "",
			telefono: "",
			despacho: false,
			editar: false,
			foto_perfil: "/assets/perfil/img_avatar.png",
			status: {},
			errors: {},
			usuario: {},
			subzonas: {},
			zonas: {},
			zona_id: -1,
			subzona_id: -1,
			flag_form: true,
			foto_base64_perfil: null
		}
	},

	mounted () {
		

		this.getUsuario();
		this.Obtenerzonas();

	},
	methods: {
		async openToast(msg) {
			const toast = await toastController.create({
				message: msg,
				duration: 2000,
				position: 'top',
			})
			return toast.present();
		},

		CambiarFoto(img) {
			if(this.flag_form == false){
				this.takePhoto().then( 
					url => {
						this.photos = window.photos
						this.foto_perfil = this.photos.webviewPath
						this.foto_base64_perfil = window.fotoperfil
					}
				);
				console.log(img);
			}
		},

	Editar(e) {
        e.preventDefault();
        var formData = new window.FormData();

        if (this.foto_base64_perfil != null) {
        	formData.append(
	          "imagen",
	          this.foto_base64_perfil
	        );
        	console.log(this.foto_base64_perfil)
        }
        formData.append(
          "rol_id",
          localStorage.getItem("_cap_rol")
        );
        formData.append(
          "usuario_id",
          this.usuario.usuario_id
        );
       
        formData.append(
          "usuario",
          this.usuario.usuario
        );

        formData.append(
          "despacho",
          this.usuario.despacho
        );

        formData.append(
          "email",
          this.usuario.usuario
        );

        formData.append(
          "rut",
          this.usuario.rut
        );

        formData.append(
          "dv",
          this.usuario.dv
        );

        formData.append(
          "subzona_id",
          this.usuario.subzona_id
        );
		formData.append(
			"zona_id",
			this.usuario.zona_id
		  );

        formData.append(
          "nombres",
          this.usuario.nombres
        );

        formData.append(
          "apellidos",
          this.usuario.apellidos
        );

        

        formData.append(
          "direccion",
          this.usuario.direccion
        );
		formData.append(
			"numero",
			this.usuario.numero
		  );

        formData.append(
          "complemento",
          this.usuario.complemento
        );

        formData.append(
          "departamento",
          this.usuario.departamento
        );

        formData.append(
          "telefono1",
          this.usuario.telefono1
        );

        formData.append(
          "telefono2",
          this.usuario.telefono2
        );

        formData.append(
          "empresa",
          this.usuario.empresa
        );
       
        console.log("perfiiiiiil");
        for (var value of formData.values()) {
		   console.log(value);
		}
        console.log("perfiiiiiil");
        window.http.post(
          process.env.VUE_APP_URL + "/api/usuario/crear-actualizar", 
          formData,
          window.headers
        ).then(
          res => {
            console.log(res)
            this.openToast(res.data.msg);
           
          }
        ).catch(
          err => {
            if (err.status === 500) {
              this.status = err.data.status
            }

            if (err.status === 422) {
              this.errors = err.data.errors
            }

            if (err.status === 401) {
              this.errors = err.data.errors
            }
            console.log(err)
          }
        );
      },

		Obtenerzonas() {
	      var formData = new window.FormData();

	      window.http.get(
	        process.env.VUE_APP_URL + "/api/zonas/get", 
	        formData,
	        window.headers
	      ).then(
	        res => {
	          this.zonas = res.data
	          console.log(res.data)
	          console.log("zonas")
	        }
	      ).catch(
	        err => {
	          if (err.status === 500) {
	            this.status = err.data.status
	          }

	          if (err.status === 422) {
	            this.errors = err.data.errors
	          }

	          if (err.status === 401) {
	            this.errors = err.data.errors
	          }
	          console.log(err)
	        }
	      );
	    },
 
	    ObtenerComuna() {
	        var formData = new window.FormData();
	        formData.append(
	        	"subzona_id",
	        	this.usuario.subzona_id
	        );
			window.http.post(
				process.env.VUE_APP_URL + "/api/subzona/get", 
					formData,
					window.headers
				).then(
				res => {
					this.usuario.zona_id = res.data.zona_id
					this.usuario.subzona_id = res.data.id
					this.ObtenerCom(this.usuario.zona_id);
					console.log("comunaa")
					console.log(res)
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
	    },

		Obtenersubzonas(select) {
	        this.ObtenerCom(select.target.value);
	    },

	    ObtenerCom(zona_id) {
	        var formData = new window.FormData();

			window.http.get(
				process.env.VUE_APP_URL + "/api/subzonas/getbyzona/" + zona_id, 
					formData,
					window.headers
				).then(
				res => {
					this.subzonas = res.data
					console.log(res.data)
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
	    },

		getUsuario() {
		    var formData = new window.FormData();
		    formData.append(
		    	"usuario_id",
		    	localStorage.getItem('_cap_usuario_id')
		    );
			window.http.post(
				process.env.VUE_APP_URL + "/api/usuario/obtener", 
				formData,
				window.headers
			  ).then(
				res => {
					this.usuario = res.data.usuario.informacion
					this.usuario.usuario = res.data.usuario.email
					this.ObtenerComuna();

					if (res.data.imagen.length > 1 && res.data.imagen != null) {
						this.foto_perfil = res.data.imagen
					}
					
					//this.usuario.fecha_nacimiento = new Date(res.data.usuario.fecha_nacimiento).toISOString();
					console.log("[EDITAR USUARIO] USUARIO");
					console.log(this.usuario);
					console.log("[EDITAR USUARIO] USUARIO");
				}
			  ).catch(
				err => {
				  if (err.status === 500) {
					this.status = err.data.status
				  }

				  if (err.status === 422) {
					this.errors = err.data.errors
				  }

				  if (err.status === 401) {
					this.errors = err.data.errors
				  }
				  console.log("error!!!!!!!!!!")
				  console.log(err)
				}
			  );
		},
 
		Logout(e) {
		  e.preventDefault();
		  
		  var formData = new window.FormData();

		  window.http.post(
			process.env.VUE_APP_URL + "/api/logout", 
			formData,
			window.headers
		  ).then(
			res => {
			  console.log(res)
			  if (res.data.exito == true) {
				localStorage.removeItem('_cap_tok_code');
				localStorage.removeItem('_cap_usuario_id');
				localStorage.removeItem('_cap_rol');
				this.$router.replace({ path: '/inicio' })
			  } else {
				this.openToast(res.data.msg)
			  }
			}
		  ).catch(
			err => {
			  if (err.status === 500) {
				this.status = err.data.status
			  }

			  if (err.status === 422) {
				this.errors = err.data.errors
			  }

			  if (err.status === 401) {
				this.errors = err.data.errors
			  }
			  console.log(err)
			}
		  );
		}
	  }
};




