<ion-page>
	<ion-header>
		
		<ion-toolbar>
			
			<ion-buttons slot="start">
				<ion-icon slot="icon-only" :icon="cogOutline" style="color: white;"></ion-icon>
				<h5  class="titulo-head">Servicios</h5>
		    </ion-buttons>
			<ion-buttons slot="end">
		        <ion-button class="btn-exit" @click="Logout">
			        <ion-icon slot="icon-only" :icon="exitOutline"></ion-icon>
			    </ion-button>
		    </ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content :fullscreen="true">
		<div class="contenido-home">
			<div class="cuerpo" >
				<ion-row responsive-lg>
			        <ion-col>
						<div  style="margin: 20px 10px;">
							<form @submit.prevent="Filtrar" >
								<ion-row responsive-lg>
									<ion-col size="8" class="col-sep">
										<ion-item class="filtro">
											<ion-select v-model="buscar.bloque"  placeholder="Buscar">
												<ion-select-option v-for="tipoBloque in tipoBloques" :value="tipoBloque.id">
													{{tipoBloque.nombre}}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col  size="4" class="col-sep">
										<ion-button class="boton-crear-principal" type="submit" >Filtrar</ion-button>
									</ion-col>
									

								</ion-row>
								
								
							</form>
						</div>
			        </ion-col>
			    </ion-row>
			    <ion-item-divider>
			      	<ion-label>Categorías disponibles</ion-label>
			    </ion-item-divider>
			    <ion-list>
					<div v-for="(cate,itemObjKey) in categorias">
						<ion-ripple-effect type="unbounded"></ion-ripple-effect>
						<ion-item button @click="getSubCategorias(cate)" lines="none" class="item-servicios" style="padding-left: 2px; padding-right: 2px; margin-top: 2px; margin-bottom: 2px;">		
					    	<div class="elem-item" style="padding-right: 2em;">
					    		<ion-thumbnail slot="start">
						        	<ion-img class="icon-serv" :src="cate.imagen"></ion-img>
						    	</ion-thumbnail>
					    	</div>
					    	<div class="elem-item">
						    	<ion-label>
									<div style="display: flex;">
										<p style="color: black; font-size: 1em; text-transform: uppercase;"><b>{{ cate.nombre }}</b></p>
										<span class="catidad">{{ cantidad[itemObjKey] }}</span>
									</div>
									<p>{{ cate.descripcion }} </p>  
								</ion-label>
						    </div>
					    </ion-item>
					</div>
				</ion-list>
			</div>
		</div>
	</ion-content>
</ion-page>
