import { 
	IonPage, 
	IonHeader, 
	IonToolbar, 
	IonTitle, 
	IonContent, 
	toastController,
	modalController, 
	IonIcon, 
} from '@ionic/vue';
import { IonButtons, IonMenuButton } from '@ionic/vue';

import ModalFormUrgencia from '../../modals/formurgencias/index.vue';
import ModalSubUrgencias from '../../modals/subcategoriasurgencias/index.vue';
import { 
  exitOutline,
  timeOutline,
} from 'ionicons/icons';

import ExploreContainer from '@/components/ExploreContainer.vue';

export default  {
	name: 'configurar',
		components: { 
		ExploreContainer, 
		IonHeader, 
		IonToolbar, 
		IonTitle, 
		IonContent, 
		IonPage,
		IonButtons,
		IonMenuButton 
	},
	setup() {
		return {
			exitOutline,
			timeOutline,
		}
	},
	data () {
		return {
			status: {},
			errors: {},
			servicios: {},
			categorias: {},
			subcategorias: {},
			cantidad: {},
			cantidadServicios: {}
		}
	},
	mounted() {

	



		this.getCategorias();
	},
  	methods: {
		async FormUrgencia(categoria){
			console.log('[PAGE CATEOGIAS] modal subcateogiras')
		  	console.log(categoria)
			console.log('[PAGE CATEOGIAS] modal subcateogiras')
		const modal = await modalController
		  .create({
			component: ModalFormUrgencia,
			componentProps: {
			  categoria: categoria
			}
		  })
		return modal.present();
		},
		
		async ModalSubCategoriaUrgencia(subcategorias,categoria) {
			console.log('[PAGE CATEOGIAS] modal subcateogiras')
		  	console.log(subcategorias)
			console.log('[PAGE CATEOGIAS] modal subcateogiras')
		const modal = await modalController
		  .create({
			component: ModalSubUrgencias,
			componentProps: {
			  subcategorias: subcategorias,
			  categoria: categoria
			}
		  })
		return modal.present();
	  },
  		async openToast(msg) {
	        const toast = await toastController.create({
	          message: msg,
	          duration: 2000,
	          position: 'top',
	        })
	        return toast.present();
	    },
		getSubCategorias(categoria){
			var formData = new window.FormData();
		
			
			formData.append(
			  "id_padre",
			  categoria.id
			);
				
			window.http.post(
			  process.env.VUE_APP_URL + "/api/subcategorias-urgencias", 
			  formData,
			  window.headers
			).then(
			  res => {
				this.subcategorias = res.data.subcategorias
				console.log('[PAGE CATEGORIAS] servicios')
				console.log(res.data)
				console.log('[PAGE CATEGORIAS] servicios')
				this.ModalSubCategoriaUrgencia(this.subcategorias,categoria)
				
			  }
			).catch(
			  err => {
				if (err.status === 500) {
				  this.status = err.data.status
				}
	
				if (err.status === 422) {
				  this.errors = err.data.errors
				}
	
				if (err.status === 401) {
				  this.errors = err.data.errors
				}
				console.log(err)
			  }
			);
		},

  		getCategorias() {
			var formData = new window.FormData();

			window.http.get(
				process.env.VUE_APP_URL + "/api/categorias-urgencias", 
				formData,
				window.headers
			).then(
				res => {
					this.categorias = res.data.categorias
					
					this.cantidad = res.data.cantidad	
					console.log("categoriasssss")
					console.log(res.data.categorias)
					console.log("categoriasssss")				
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},
 
		Logout(e) {
			e.preventDefault();
			var formData = new window.FormData();
			window.http.post(
				process.env.VUE_APP_URL + "/api/logout", 
				formData,
				window.headers
			).then(
				res => {
					console.log(res)
					if (res.data.exito == true) {
						localStorage.removeItem('_cap_tok_code');
						localStorage.removeItem('_cap_usuario_id');
						localStorage.removeItem('_cap_rol');
						this.$router.replace({ path: '/inicio' })
					} else {
						this.openToast(res.data.msg)
					}
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		}
	}
};