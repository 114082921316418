<ion-page>
	<ion-header>
		<ion-toolbar>
			
			<ion-buttons slot="start">
				<ion-icon slot="icon-only" :icon="newspaper" style="color: white;"></ion-icon>
				<h5  class="titulo-head">Solicitudes</h5>
		    </ion-buttons>
			<ion-buttons slot="end">
		        <ion-button class="btn-exit" @click="Logout">
			        <ion-icon slot="icon-only" :icon="exitOutline"></ion-icon>
			    </ion-button>
		    </ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content :fullscreen="true">
				<div class="contenido-home">
					<div class="cuerpo" v-if="flag_form">
						<ion-item-divider>
							  <ion-label>Mis Solicitudes</ion-label>
						</ion-item-divider>

						<ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
							<ion-refresher-content>
							</ion-refresher-content>
						</ion-refresher>
						
						<ion-list>
							<div v-for="(servicio,itemObjKey) in servicios">
								<ion-ripple-effect type="unbounded"></ion-ripple-effect>
								<ion-item button @click="ModalDetalleSolicitud(servicio,solicitudes[itemObjKey])" lines="none" class="item-servicios" style="padding-left: 2px; padding-right: 2px; margin-top: 2px; margin-bottom: 2px;">		
									<div class="elem-item" style="padding-right: 2em;">
										<ion-thumbnail slot="start">
											<ion-img class="icon-serv" :src="servicio.categoria.imagen"></ion-img>
										</ion-thumbnail>
									</div>
									<div class="elem-item">
										<ion-label>
											<p style="color: black; font-size: 0.8em;">
												<b>{{ servicio.nombre }}</b>
											</p>
											<p>{{ servicio.categoria.nombre }}</p>
											<p>
												{{ solicitudes.fecha_programacion }}
											</p>
											<p>
												Estado: <strong style="color:#9b9797">{{ solicitudes[itemObjKey].estado.nombre }}</strong>
											</p>
										</ion-label>
									</div>
								</ion-item>
							</div>
						</ion-list>			
					</div>
		
					<div class="cuerpo" v-else>
						<ion-row responsive-lg>
							<ion-col>
								<ion-card class="card_form_config_serv">
									<ion-item-divider>
										  <ion-label>Formulario Solicitud</ion-label>
									</ion-item-divider>
									<form @submit.prevent="Solicitar">
										<br>
										<ion-row responsive-lg>
											<ion-col size="12" class="col-sep">
												<ion-item>
													<ion-select 
														v-model="solicitud.servicio_id"
														id="tipo_servicio"
														placeholder="Seleccione un servicio"
														required>
														<ion-select-option 
															v-for="serv in servicios" 
															:value="serv.id">
																{{ serv.nombre }}
														</ion-select-option>
													</ion-select>
												</ion-item>
											</ion-col>
										</ion-row>
										<br>
										<ion-row responsive-lg>
											<ion-col size="12" class="col-sep">
												<ion-item>
													<ion-textarea 
													  clear-on-edit="true"
													  v-model="solicitud.extra"
													  placeholder="Comentarios al proveedor" 
													  name="extra" 
													  inputmode="text" 
													  required>
													</ion-textarea>
												</ion-item>
											</ion-col>
										</ion-row>
										<br>
										<ion-row responsive-lg>
											<ion-col size="12" class="col-sep">
												<ion-item>
													{{anoMinimo.getFullYear()}}<ion-datetime 
													v-model="solicitud.fecha_programacion"
													placeholder="Fecha Solicitud"
													value="2019-10-01T15:43:40.394Z" 
													display-timezone="utc"
													monthShortNames="Ene, Feb, Mar, Abril, Mayo, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
													displayFormat="MMM DD, YYYY HH:mm">
													</ion-datetime>
												</ion-item>
											</ion-col>
										</ion-row>
										<!--
										<br>
										<ion-row responsive-lg>
											<ion-col size="12" class="col-sep">
												<ion-item>
													<ion-select 
														v-model="solicitud.pago_id"
														id="pago_id"
														placeholder="Tipo pago">
														<ion-select-option 
															v-for="pago in pagos" 
															:value="pago.id">
																{{ pago.nombre }}
														</ion-select-option>
													</ion-select>
												</ion-item>
											</ion-col>
										</ion-row>
										<br>
										<ion-row responsive-lg>
											<ion-col size="12" class="col-sep">
												<ion-item>
													<ion-select 
														v-model="solicitud.estado_solicitud_id"
														id="estado_solicitud_id"
														placeholder="Estado solicitud">
														<ion-select-option 
															v-for="estados_sol in estados_sols" 
															:value="estados_sol.id">
																{{ estados_sol.nombre }}
														</ion-select-option>
													</ion-select>
												</ion-item>
											</ion-col>
										</ion-row>
										<br>
										<ion-row responsive-lg>
											<ion-col size="12" class="col-sep">
												<ion-item>
													<ion-select 
														v-model="solicitud.metodo_de_pago"
														id="metodo_de_pago"
														placeholder="Método pago">
														<ion-select-option 
															v-for="metodo_de_pago in metodo_de_pagos" 
															:value="metodo_de_pago.id">
																{{ metodo_de_pago.nombre }}
														</ion-select-option>
													</ion-select>
												</ion-item>
											</ion-col>
										</ion-row>
										-->
										<br>
										<ion-row>
											<ion-col>
												<ion-button 
												  type="submit" 
												  expand="block">
													Pagar
												</ion-button>
											</ion-col>
										</ion-row>
									</form>
								</ion-card>
							</ion-col>
						</ion-row>
					</div>
				</div>
	</ion-content>
</ion-page>