import { 
	IonContent, 
	IonHeader, 
	IonTitle, 
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	modalController
} from '@ionic/vue';
import { Terminos } from 'vue';
import ModalFormUrgencia from '../../modals/formurgencias/index.vue';
import ServicioModal from '../../modals/servicios/index.vue';
import UnServicioModal from '../../modals/servicio/index.vue';

import { 
  exitOutline,
  chevronBackOutline,
  checkmark,
  ban
} from 'ionicons/icons';
export default {
  name: 'servicios-modal',
  components: { 
  	IonContent, 
  	IonHeader, 
  	IonTitle, 
  	IonToolbar,
  	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent
  },
  props: {
    subcategorias: {
      type: Object,
      default: {}
    },
	categoria: {
		type: Object,
		default: {}
	  }
  },
  setup() {
  	return {
  		chevronBackOutline,
		checkmark,
		ban
    };
  },
  data() {
    return {
		serviciosCategoria:{}
    }
  },
  mounted() {
	console.log('[ MODAL SUBCATEGORIAS ]categoriaaas')
    console.log(this.subcategorias)
	console.log('[ MODAL SUBCATEGORIAS ]categoriaaas')
  },
  methods: {
  	Back() {
  		modalController.dismiss();
  	},
	  async FormUrgencia(categoria){
		console.log('[PAGE CATEOGIAS] modal subcateogiras')
		  console.log(categoria)
		console.log('[PAGE CATEOGIAS] modal subcateogiras')
	const modal = await modalController
	  .create({
		component: ModalFormUrgencia,
		componentProps: {
		  categoria: categoria
		}
	  })
	return modal.present();
	},
	getServiciosCategorias(categoria){
		var formData = new window.FormData();
	
		console.log('[MODAL SUBCATEGORIAS] id categoria')
		console.log(categoria.id)
		console.log('[MODAL SUBCATEGORIAS] id categoria')
		formData.append(
		  "id_categoria",
		  categoria.id
		);
			
		window.http.post(
		  process.env.VUE_APP_URL + "/api/servicios/categoria", 
		  formData,
		  window.headers
		).then(
		  res => {
			  console.log('[MODAL SUBCATEGORIAS] servicioss')
			  console.log(res.data)
				console.log('[MODAL SUBCATEGORIAS] servicioss')
				this.serviciosCategoria=res.data;
			this.ModalServiciosSubCategoria(this.serviciosCategoria,categoria)
		  }
		).catch(
		  err => {
			if (err.status === 500) {
			  this.status = err.data.status
			}

			if (err.status === 422) {
			  this.errors = err.data.errors
			}

			if (err.status === 401) {
			  this.errors = err.data.errors
			}
			console.log(err)
		  }
		);
	},
	async FormUrgencia(categoria){
		console.log('[PAGE CATEOGIAS] modal subcateogiras')
		  console.log(categoria)
		console.log('[PAGE CATEOGIAS] modal subcateogiras')
	const modal = await modalController
	  .create({
		component: ModalFormUrgencia,
		componentProps: {
		  categoria: categoria
		}
	  })
	return modal.present();
	},
	  async ModalServiciosSubCategoria(serviciosCategoria,categoria) {
		console.log('[MODAL SUBCATEGORIAS] modal servicios')
		console.log(serviciosCategoria)
		console.log('[MODAL SUBCATEGORIAS] modal servicios')
	  const modal = await modalController
		.create({
		  component: ServicioModal,
		  componentProps: {
			title: 'New Title',
			serviciosCategoria: serviciosCategoria,
			categoria: categoria
		  }
		})
	  return modal.present();
	},
	async ModalDetalleServicio(servicio) {
		console.log('[MODAL SERVICIOS] detalle serivicio')
		console.log(servicio)
		console.log('[MODAL SERVICIOS] detalle serivicio')
	  const modal = await modalController
		.create({
		  component: UnServicioModal,
		  componentProps: {
			title: 'New Title',
			servicio: servicio,
		  }
		})
	  return modal.present();
	},
  }
};