<ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-icon slot="icon-only" :icon="cogOutline" style="color: white;"></ion-icon>
				<h5  class="titulo-head">Servicios</h5>
		    </ion-buttons>
			<ion-buttons slot="end">
		        <ion-button class="btn-exit" @click="Logout">
			        <ion-icon slot="icon-only" :icon="exitOutline"></ion-icon>
			    </ion-button>
		    </ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content :fullscreen="true">
		<div class="contenido-home">
			<div class="cuerpo" v-if="flag_form">
				<ion-row responsive-lg>
			        <ion-col>
						<div class="cen-vh" style="margin: 20px 10px;">
							<ion-button class="boton-crear-principal" @click="ShowFormServ()">Crear Nuevo Servicio</ion-button>
						</div>
			        </ion-col>
			    </ion-row>

			    <ion-item-divider>
			      	<ion-label>Mis Servicios</ion-label>
			    </ion-item-divider>
			    <ion-list>
					<div v-for="(serv,itemObjKey) in servicios">
						<ion-ripple-effect type="unbounded"></ion-ripple-effect>
						<ion-item button @click="ModalEditarServicio(serv)" lines="none" class="item-servicios" style="padding-left: 2px; padding-right: 2px; margin-top: 2px; margin-bottom: 2px;">		
					    	<div class="elem-item" style="padding-right: 2em;">
					    		<ion-thumbnail slot="start">
						        	<img class="icon-serv" :src="serv.categoria.imagen">
						    	</ion-thumbnail>
					    	</div>
					    	<div class="elem-item">
						    	<ion-label>
									<p style="color: black; font-size: 0.8em;"><b>{{ serv.nombre }}</b></p>
									<p>{{ serv.categoria.nombre }}</p>
								</ion-label>
						    </div>
					    </ion-item>
					</div>
				</ion-list>
			</div>
			<div class="cuerpo" v-else>
				<ion-row responsive-lg>
			        <ion-col>
						<ion-card class="card_form_config_serv">
			    	<ion-item-divider>
						<ion-buttons slot="end">
							<ion-button class="btn-exit" @click="Back">
								<ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
							</ion-button>
						</ion-buttons>
				      	<ion-label>Formulario Servicio</ion-label>
				    </ion-item-divider>
					<form @submit.prevent="ConfigurarServicio">
						<ion-row responsive-lg>
							<ion-col size="12" class="col-sep">
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
										<ion-input
										  v-model="servicio.nombre"
							              placeholder="Nombre" 
							              name="nombre" 
										  required
							              inputmode="text" 
							              >
							            </ion-input>
							        </ion-item>
									</ion-col>
								</ion-row>
	
								<ion-row responsive-lg>
									<!-- <ion-col size="6" class="col-sep">
										<ion-item>
											<ion-select 
												v-model="servicio.tipo_servicio_id"
												id="tipo_servicio"
												placeholder="Tipo Servicio">
												<ion-select-option 
													v-for="tipo in tipos_servicios" 
													:value="tipo.id">
														{{ tipo.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col> -->
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-select v-model="servicio.categoria_id"  placeholder="Categoría">
												<ion-select-option v-for="categoria in categorias" :value="categoria.id">
													{{ categoria.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									
									<ion-col size="12" class="col-sep" v-if="servicio.categoria_id == categoriaComida">
										<ion-item>
											<ion-select v-model="servicio.marca_id"  placeholder="Marca">
												<ion-select-option v-for="marca in marcas" :value="marca.id">
													{{ marca.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep" v-if="servicio.categoria_id == categoriaComida">
										<ion-item>
											<ion-select v-model="servicio.kilo_id"  placeholder="Kilo">
												<ion-select-option v-for="kilo in kilos" :value="kilo.id">
													{{ kilo.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep" v-if="servicio.categoria_id == categoriaComida">
										<ion-item>
											<ion-select v-model="servicio.raza_id"  placeholder="Raza">
												<ion-select-option v-for="raza in razas" :value="raza.id">
													{{ raza.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
								</ion-row>
							
								<ion-row responsive-lg>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-select  @ionChange="ObtenerComunas($event)" v-model="servicio.zonas" placeholder="Zona">
												<ion-select-option v-for="zona in zonas" :value="zona.id">
													{{ zona.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-select  v-model="servicio.subzonas"  placeholder="Subzona">
												<ion-select-option v-for="subzona in subzonas" :value="subzona.id">
													{{ subzona.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
								</ion-row>

								<ion-row responsive-lg>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-input
										  	  v-model="servicio.valor" 
								              placeholder="Valor" 
								              name="valor" 
								              type="number" 
								              >
								            </ion-input>
										</ion-item>
									</ion-col>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-select v-model="servicio.estado_id"  placeholder="Estado">
												<ion-select-option v-for="estado in estados_servicio" :value="estado.id">{{ estado.nombre }}</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
								</ion-row>

					            <ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
										<ion-input
										  v-model="servicio.descripcion" 
							              placeholder="Descripción" 
							              name="descripcion" 
							              type="text" 
							              >
							            </ion-input>
							        </ion-item>
									</ion-col>
								</ion-row>
								<br>
					        	<ion-item-divider class="classdias">
							      	<ion-label>Horarios</ion-label>
							    </ion-item-divider>
							    <ion-row responsive-lg>
									<ion-col size="6" class="col-sep">
										<ion-item>
										<ion-datetime v-model="servicio.inicio" placeholder="Mañana" display-format="HH:mm"  ></ion-datetime>
									</ion-item>
									</ion-col>
	
									<ion-col size="6" class="col-sep">
										<ion-item>
										<ion-datetime v-model="servicio.termino"  placeholder="Término" display-format="HH:mm"></ion-datetime>
									</ion-item>
									</ion-col>
								</ion-row>
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
										<ion-input
										  v-model="servicio.duracion_servicio"
							              placeholder="Duracion"
							              name="duracion_servicio" 
										  type="number" maxlength="3"
							              required>
							            </ion-input><ion-label>Minutos</ion-label>
							        </ion-item>
									</ion-col>
								</ion-row>
								<!--<ion-row responsive-lg>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-label>Mañana</ion-label>
											<ion-toggle placeholder="Mañana"  @ionChange="servicio.manana = !servicio.manana"></ion-toggle>
										</ion-item>
									</ion-col>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-label>Tarde</ion-label>
											<ion-toggle placeholder="Tarde"  @ionChange="servicio.tarde = !servicio.tarde"></ion-toggle>
										</ion-item>
									</ion-col>
								</ion-row>-->
								<br>
								<ion-item-divider class="classdias">
							      	<ion-label>Días disponibles</ion-label>
							    </ion-item-divider>
					            <ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Lunes</ion-label>
											<ion-toggle placeholder="Lunes" @ionChange="servicio.lunes = !servicio.lunes"></ion-toggle>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Martes</ion-label>
											<ion-toggle placeholder="Martes" @ionChange="servicio.martes = !servicio.martes"></ion-toggle>
										</ion-item>
									</ion-col>
								</ion-row>
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Miércoles</ion-label>
											<ion-toggle placeholder="Miércoles" @ionChange="servicio.miercoles = !servicio.miercoles"></ion-toggle>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Jueves</ion-label>
											<ion-toggle placeholder="Jueves" @ionChange="servicio.jueves = !servicio.jueves"></ion-toggle>
										</ion-item>
									</ion-col>
								</ion-row>
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Viernes</ion-label>
											<ion-toggle placeholder="Viernes" @ionChange="servicio.viernes = !servicio.viernes"></ion-toggle>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Sábado</ion-label>
											<ion-toggle placeholder="Sábado" @ionChange="servicio.sabado = !servicio.sabado"></ion-toggle>
										</ion-item>
									</ion-col>
								</ion-row>
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Domingo</ion-label>
											<ion-toggle placeholder="Domingo" v-model="servicio.domingo" @ionChange="servicio.domingo = !servicio.domingo" ></ion-toggle>
										</ion-item>
									</ion-col>
								</ion-row>
							</ion-col>
						</ion-row>
						<ion-row>
				          <ion-col>
				              <ion-button type="submit" expand="block">
				              	CREAR SERVICIO
				              </ion-button>
				          </ion-col>
				        </ion-row>
					</form>
				</ion-card>
					</ion-col>
				</ion-row>
			</div>
		</div>
	</ion-content>
</ion-page>