import { ref, onMounted, watch } from 'vue';
import { Plugins, CameraResultType, CameraSource, CameraPhoto, Capacitor, FilesystemDirectory } from '@capacitor/core';
import { isPlatform } from '@ionic/vue';
import { actionSheetController } from '@ionic/vue';
import { trash, close } from 'ionicons/icons';

export class Photo {
	constructor(
			filepath= "",
			webviewPath= ""
		) {
		this.filepath    = filepath;
		this.webviewPath = webviewPath;
	}
}

export function usePhotoGallery() {
	var fileName = 'foto_perfil.jpeg';
	var foto_perfil = "";
	var PHOTO_STORAGE = "photos";
	var { Camera, Filesystem, Storage } = Plugins;
	
	var photos = new Photo();
	

	var convertBlobtoBase64 = function (blob) { 
		return new Promise(function (resolve, reject) {
			var reader = new FileReader;
			reader.onerror = reject;
			reader.onload = function () {
					resolve(reader.result);
			};
			reader.readAsDataURL(blob);
		}); 
	};


	var savePicture = async function (photo, fileName) {
		var base64Data;

		if (isPlatform('hybrid')) {
			var file = await Filesystem.readFile({
				path: photo.path
			});
			base64Data = file.data;
		} else {
			var response = await fetch(photo.webPath);
			var blob = await response.blob();
			base64Data = await convertBlobtoBase64(blob);
		}

		var savedFile = await Filesystem.writeFile({
			path: fileName,
			data: base64Data,
			directory: FilesystemDirectory.Data
		});

		window.fotoperfil = base64Data


		if (isPlatform('hybrid')) {
			return {
				filepath: savedFile.uri,
				webviewPath: Capacitor.convertFileSrc(savedFile.uri)
			};
		} else {
			return {
				filepath: fileName,
				webviewPath: photo.webPath
			};
		}
	}

	var cachePhotos = function () {
		Storage.set({
			key: PHOTO_STORAGE,
			value: JSON.stringify(photos.value)
		});
	};

	var deletePhoto = async function (photo) {
		/*
		// remove photo from array
		photos.value = photos.value.filter(function (p) { return p.filepath !== photo.filepath; });
		// remove photo from file system
		var filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
		*/
		await Filesystem.deleteFile({
				path: filename,
				directory: FilesystemDirectory.Data
		});

	}

	var showActionSheet = async function (photo) {
		var actionSheet = await actionSheetController.create({
			header: 'Photos',
			buttons: [{
				text: 'Eliminar',
				role: 'destructive',
				icon: trash,
				handler: function () {
						deletePhoto(photo);
				}
			}, {
				text: 'Cancelar',
				icon: close,
				role: 'cancel',
				handler: function () {
				}
			}]
		});
		await actionSheet.present();
	}
	watch(photos, cachePhotos);

	var loadSaved = async function() {
		var photoList = await Storage.get({ key: PHOTO_STORAGE });
		var photosInStorage = photoList.value ? JSON.parse(photoList.value) : [];

		if (!isPlatform('hybrid')) {
			for (var _i = 0, photosInStorage_1 = photosInStorage; _i < photosInStorage_1.length; _i++) {
				var photo = photosInStorage_1[_i];
				var file = await Filesystem.readFile({
					path: photo.filepath,
					directory: FilesystemDirectory.Data
				});
				photo.webviewPath = "data:image/jpeg;base64," + file.data;
			}
		}
		photos.value = photosInStorage;
		console.log(photosInStorage)
	}
	onMounted(loadSaved);

	var takePhoto = async function() {
		var cameraPhoto = await Camera.getPhoto({
			resultType: CameraResultType.Uri,
			source: CameraSource.Camera,
			quality: 100
		});

		//new Date().getTime() + '.jpeg';
		var savedFileImage = await savePicture(cameraPhoto, fileName);
		//photos.value = [savedFileImage, photos.value];
		//photos.filepath = savedFileImage.filepath;
		//photos.webviewPath = savedFileImage.webviewPath;
		//photos = savedFileImage.webviewPath;
		
		photos = savedFileImage;

		console.log(photos);
		window.photos = photos;
	};


	return {
		//foto_perfil,
		 photos,
		 takePhoto,
		 deletePhoto,
		 showActionSheet
	};
}