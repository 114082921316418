<ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
		        <ion-button class="btn-exit" @click="Back">
			        <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
			    </ion-button>
		    </ion-buttons>
			<ion-buttons slot="start">
				<h5 class="titulo-head">Formulario</h5>
		    </ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content>
		<div class="contenido-home">
			<ion-row responsive-lg>
		        <ion-col>
			        <ion-card class="card-terminos">
			            <ion-card-content>
									<form @submit.prevent="EnviarUrgencia" enctype="multipart/form-data">
										<ion-col size="12" class="col-sep">
											<ion-label class="text-color-primario">
												<b>Describa la Urgencia</b>
											</ion-label>
										</ion-col>
										<ion-row responsive-lg>
											<ion-col size="12" class="col-sep">
												<ion-item>
													<textarea
													v-model="urgencia.descripcion"
													placeholder="Descripción"
													required
													style="min-width: 100% !important;"
												>
													</textarea>
												</ion-item>
											</ion-col>
												
										</ion-row>
										<ion-col size="12" class="col-sep">
											<ion-label class="text-color-primario">
												<b>Agregar fotografías</b>
											</ion-label>
										</ion-col>
										<ion-row responsive-lg>
											<ion-col size="12" class="col-sep">
												<ion-row responsive-lg>
													<ion-col size="6" class="col-sep">
														<ion-item>
															<div class="cab-prefil">
																<div class="foto-perfil ion-activatable ripple-parent"  >
																	<ion-ripple-effect type="unbounded" ></ion-ripple-effect>
																	<img v-bind:src="imagen_1"   @click="TomarFoto(this)">
																	<p>
																		Capturar Imagen
																	</p>
																</div>
															</div>
														</ion-item>
													</ion-col>
													<ion-col size="6" class="col-sep">
														<ion-item>
															<div class="cab-prefil">
																<div class="foto-perfil ion-activatable ripple-parent" style="background-color: ghostwhite;">
																	<ion-ripple-effect type="unbounded"  ></ion-ripple-effect>
																	

																	<img v-bind:src="imagen_2"   @click="TomarFoto2(this)">
																	<p>
																		Capturar Imagen
																	</p>
																</div>
															</div>
														</ion-item>
													</ion-col>
												</ion-row>
											</ion-col>
										</ion-row>

										
										
									
										
										
										<ion-row responsive-lg>
											<ion-col>
											  <ion-button type="submit" expand="block">
												Enviar
											  </ion-button>
											</ion-col>
										  </ion-row>
									</form>
					    </ion-card-content>
				    </ion-card>
				</ion-col>
			</ion-row>
		</div>
	</ion-content>
</ion-page>