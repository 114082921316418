import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';
import eventBus from '../../../eventBus.js';

import { 
  exitOutline,
  chevronBackOutline,
  newspaper,
} from 'ionicons/icons';
import SolicitudModal from '../../modals/solicitudes/index.vue';
import { 
  IonButtons, 
  IonMenuButton,
  IonLabel,
  IonCol,
  IonRow,
  IonCardContent,
  IonCard,
  IonItemDivider,
  IonList,
  IonSelectOption,
  IonSelect,
  IonItem,
  IonTextarea,
  IonDatetime,
  toastController,
  IonThumbnail,
  IonImg,
  IonRippleEffect,
  IonButton,
  IonIcon,
  modalController
} from '@ionic/vue';

export default  {
  name: 'solicitudes',
  components: { 
    ExploreContainer, 
    IonMenuButton,
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent, 
    IonPage,
    IonButtons,
    IonIcon,
    IonLabel,
    IonCol,
    IonRow,
    IonCardContent,
    IonCard,
    IonItemDivider,
    IonList,
    IonSelectOption,
    IonSelect,
    IonItem,
    IonTextarea,
    IonDatetime,
    IonThumbnail,
    IonImg,
    IonRippleEffect,
    IonButton,
    IonIcon,



  },
  setup() {
    const items = [
      {
        'text': 'Mascotas',
        'src': '/assets/icon/iconlist.jpg'
      }, {
        'text': 'Limpieza',
        'src': '/assets/icon/iconlist.jpg'
      }, {
        'text': 'Jardin y exterior',
        'src': '/assets/icon/iconlist.jpg'
      }, {
        'text': 'Repuestos',
        'src': '/assets/icon/iconlist.jpg'
      }, {
        'text': 'Comestibles y bebestibles',
        'src': '/assets/icon/iconlist.jpg'
      }, {
        'text': 'Otros',
        'src': '/assets/icon/iconlist.jpg'
      }
    ];

    return {
      exitOutline,
      items,
      chevronBackOutline,
      newspaper
    }
  },
  data () {
    return {
      status: {},
      errors: {},
      flag_form: true,
      solicitud: {},
      servicios: {},
      solicitudes: {},
      metodo_de_pagos: {},
      estados_sols: {},
      pagos: {},
      volver: true
    }
  },
  mounted() {

   

    this.getSolicitudes();
        
    //console.log('[PAGE SOLICITUDES] solicitudes')
    //console.log(this.servicios)
    //console.log('[PAGE SOLICITUDES] solicitudes')
    eventBus.$on('update-solicitudes-proveedor', () => {
      console.log("eventBus -> update-solicitudes-proveedor");
      this.getSolicitudes();
    });
  },
    methods: {
      
      Back() {
        this.volver = false
        this.flag_form = true

      },
      doRefresh(event){
        setTimeout(() => {
          console.log('Async operation has ended');
          this.getSolicitudes();
          event.target.complete();
        }, 2000);
      },
      async ModalDetalleSolicitud(servicio,solicitud) {
        const modal = await modalController
          .create({
            component: SolicitudModal,
            componentProps: {
              servicio:servicio,
              solicitud: solicitud,
              terminada: false,
            }
          })
        return modal.present();
      },

      async openToast(msg) {
          const toast = await toastController.create({
            message: msg,
            duration: 2000,
            position: 'top',
          })
          return toast.present();
      },

      getSolicitudes() {
        var formData = new window.FormData();
        window.http.get(
          process.env.VUE_APP_URL + "/api/solicitudes/get/proveedor/" + localStorage.getItem('_cap_usuario_id'), 
          formData,
          window.headers
        ).then(
          res => {
            this.servicios = res.data.servicios
            this.solicitudes = res.data.solicitudes
            console.log("[PAGE SOLICITUDES] GET servicios-solicitudes")
            console.log(this.servicios)
            console.log(this.solicitudes)
            console.log("[PAGE SOLICITUDES] GET servicios-solicitudes")
          }
        ).catch(
          err => {
            if (err.status === 500) {
              this.status = err.data.status
            }

            if (err.status === 422) {
              this.errors = err.data.errors
            }

            if (err.status === 401) {
              this.errors = err.data.errors
            }
            console.log(err)
          }
        );
      },

      Solicitar(e) {
        e.preventDefault();
        var formData = new window.FormData();
       
        formData.append(
          "usuario_id",
          localStorage.getItem('_cap_usuario_id')
        );
        var aux = this.solicitud.fecha_programacion.split("T");
        var fecha = aux[0];
        var hora = aux[1].split(".")[0];

        formData.append(
          "fecha_programacion",
          fecha + " " + hora
        );

        formData.append(
          "servicio_id",
          this.solicitud.servicio_id
        );

        formData.append(
          "extra",
          this.solicitud.extra
        );

        console.log(formData.values);
        
        window.http.post(
          process.env.VUE_APP_URL + "/api/pagar-solicitud", 
          formData,
          window.headers
        ).then(
          res => {
            this.getSolicitudes();
            this.openToast(res.data.msg)
            this.solicitudes = res.data
            console.log(this.solicitudes)
            this.Back();
          }
        ).catch(
          err => {
            if (err.status === 500) {
              this.status = err.data.status
            }

            if (err.status === 422) {
              this.errors = err.data.errors
            }

            if (err.status === 401) {
              this.errors = err.data.errors
            }
            console.log(err)
          }
        );
      },

      ShowFormSol() {
        this.flag_form = !this.flag_form;
      },
 
      Logout(e) {
        e.preventDefault();

        var formData = new window.FormData();

        window.http.post(
          process.env.VUE_APP_URL + "/api/logout", 
          formData,
          window.headers
        ).then(
          res => {
            console.log(res)
            if (res.data.exito == true) {
              localStorage.removeItem('_cap_tok_code');
              localStorage.removeItem('_cap_usuario_id');
              localStorage.removeItem('_cap_rol');
              this.$router.replace({ path: '/inicio' })
            } else {
              this.openToast(res.data.msg)
            }
          }
        ).catch(
          err => {
            if (err.status === 500) {
              this.status = err.data.status
            }

            if (err.status === 422) {
              this.errors = err.data.errors
            }

            if (err.status === 401) {
              this.errors = err.data.errors
            }
            console.log(err)
          }
        );
      }

  }
};


