import { 
	IonContent, 
	IonHeader, 
	IonTitle, 
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	modalController
} from '@ionic/vue';
import { Terminos } from 'vue';
import PagarServicio from '../../modals/pagar/index.vue';
import { 
  exitOutline,
  chevronBackOutline,
  checkmark,
  ban
} from 'ionicons/icons';
export default {
  name: 'un-servicios-modal',
  components: { 
  	IonContent, 
  	IonHeader, 
  	IonTitle, 
  	IonToolbar,
  	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent
  },
  props: {
    title: { 
    	type: String, 
    	default: 'Super Modal' 
    },
    servicio: {
      type: Object,
      default: {}
    }
  },
  setup() {
  	return {
  		chevronBackOutline,
		checkmark,
		ban
    };
  },
  data() {
    return {
    }
  },
  mounted() {
	
    console.log(this.servicio)
  },
  methods: {
  	Back() {
  		modalController.dismiss();
  	},
	async ServicioPagar(servicio)
	{
		console.log('[MODAL SERVICIO] pagar serivicio')
		console.log(servicio)
		console.log('[MODAL SERVICIO] pagar serivicio')
	  const modal = await modalController
		.create({
		  component: PagarServicio,
		  componentProps: {
			title: 'New Title',
			servicio: servicio,
		  }
		})
	  return modal.present();
	}
  }
};