import { 
  IonTabBar, 
  IonTabButton, 
  IonTabs,
  IonLabel, 
  IonIcon, 
  IonPage 
} from '@ionic/vue';


export default {
  name: 'inicio',
  components: { 
    IonLabel, 
    IonTabs, IonTabBar, 
    IonTabButton, 
    IonIcon, 
    IonPage 
  },
  mounted () {
    var token = window.localStorage.getItem("_cap_tok_code");
    if (token != null) {
      window.http.defaults.headers.common["Authorization"] = "Bearer" + token;
      this.$router.replace({ path: '/home' })
    }
  }
};