import { createRouter, createWebHistory } from '@ionic/vue-router';
import Inicio from '@/views/inicio/index.vue'

import Login from '@/views/inicio/login/index.vue'
import Registro from '@/views/inicio/registro/index.vue'
import Recuperar from '@/views/inicio/recuperar/index.vue'


import UrgenciasC from '@/views/home-cliente/urgencias/index.vue'
import ConfigurarC from '@/views/home-cliente/configurar/index.vue'
import SolicitudesC from '@/views/home-cliente/solicitudes/index.vue'
import RealizadasC from '@/views/home-cliente/realizadas/index.vue'
import PerfilesC from '@/views/home-cliente/perfiles/index.vue'

import HomeCliente from '@/views/home-cliente/index.vue'

import ConfigurarP from '@/views/home-proveedor/configurar/index.vue'
import SolicitudesP from '@/views/home-proveedor/solicitudes/index.vue'
import RealizadasP from '@/views/home-proveedor/realizadas/index.vue'
import PerfilesP from '@/views/home-proveedor/perfiles/index.vue'

import HomeProveedor from '@/views/home-proveedor/index.vue'
import Exito from '@/views/exito-cliente/index.vue'

import { Plugins } from "@capacitor/core";

function authGuard(to, from, next) {
	console.log([to, from, next]);
	const publicPages = [
		"/inicio/login", 
		"/inicio/registro", 
		"/inicio/recuperar",
		"/exito_webpay",
		"/"
	];
	const pagesProv = [
		"/home-proveedor/configurar", 
		"/home-proveedor/realizadas", 
		"/home-proveedor/realizadas",
		"/home-proveedor/perfiles"
	];
	const pagesCli = [
		"/home-cliente/urgencias", 
		"/home-cliente/configurar", 
		"/home-cliente/realizadas", 
		"/home-cliente/realizadas",
		"/home-cliente/perfiles"
	];
	
	const authRequired = !publicPages.includes(to.path);
	var loggedIn;
	loggedIn = window.localStorage.getItem("_cap_tok_code");
	var rol = window.localStorage.getItem("_cap_rol");
	
	if ((to.path != from.path) && loggedIn == null && (pagesCli.includes(to.path) == true && rol != 3 || pagesProv.includes(to.path) == true && rol != 2)) {
		return next("/inicio/login");
	} 

	if ((to.path != from.path) && loggedIn == null && !pagesCli.includes(to.path) && !pagesProv.includes(to.path) && !publicPages.includes(to.path)) {
		return next("/inicio/login");
	}
	next()
}

const { Storage } = Plugins
const routes = [
	{ 
		path: '/exito_webpay/:json_recibido', 
		name: 'Exito',
		component: () => import('@/views/exito-cliente/index.vue')
	},
	{ 
		path: '/', 
		redirect: "/inicio/",
	},
	{
		path: '/inicio/',
		component: Inicio,

		children: [
			{
				path: '',
				redirect: '/inicio/login'
			},
			{
				path: 'login',
				component: Login,
				name: "login"
			},
			{
				path: 'registro',
				component: Registro
			},
			{
				path: 'recuperar',
				component: Recuperar
			}
		]
	},
	{
		path: '/home-cliente/',
		component: HomeCliente,
		beforeEnter: authGuard,
		redirect: '/home-cliente/configurar',
		children: [
			{
				path: 'urgencias',
				component: UrgenciasC
			},
			{
				path: 'configurar',
				component: ConfigurarC
			},
			{
				path: 'solicitudes',
				component: SolicitudesC
			},
			{
				path: 'realizadas',
				component: RealizadasC
			},
			{
				path: 'perfiles',
				component: PerfilesC
			}
		]
	}, 
	{
		path: '/home-proveedor/',
		component: HomeProveedor,
		beforeEnter: authGuard,
		redirect: '/home-proveedor/configurar',
		children: [
			{
				path: '',
				redirect: '/home-proveedor/configurar'
			},
			{
				path: 'configurar',
				component: ConfigurarP
			},
			{
				path: 'solicitudes',
				component: SolicitudesP
			},
			{
				path: 'realizadas',
				component: RealizadasP
			},
			{
				path: 'perfiles',
				component: PerfilesP
			},

		]
	}, 
	{ 
		path: '/:pathMatch(.*)*', 
		redirect: '/',
	}
]


const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	linkActiveClass: 'active'
})
export default router