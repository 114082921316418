import { 
	IonContent, 
	IonHeader, 
	IonTitle, 
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	modalController,
	IonPage,
	IonList,
	IonImg,
	IonRippleEffect,
	IonItemDivider,
	IonLabel,
	IonItem,
	IonSelect,
	IonSelectOption,
} from '@ionic/vue';
import { Terminos } from 'vue';
import UnServicioModal from '../../modals/servicio/index.vue';
import { 
  exitOutline,
  chevronBackOutline,
  checkmark,
  ban
} from 'ionicons/icons';
export default {
  name: 'servicios-modal',
  components: { 
  	IonContent, 
  	IonHeader, 
  	IonTitle, 
  	IonToolbar,
  	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	IonPage,
	IonList,
	IonImg,
	IonRippleEffect,
	IonItemDivider,
	IonLabel,
	IonItem,
	IonSelect,
	IonSelectOption,
  },
  props: {
    title: { 
    	type: String, 
    	default: 'Super Modal' 
    },
    serviciosCategoria: {
      type: Object,
      default: {}
    },
	categoria: {
		type: Object,
		default: {}
	  },
	  comidaPerro: {
		  type: Object,
		  default: {}
	  }
  },
  setup() {
  	return {
  		chevronBackOutline,
		checkmark,
		ban
    };
  },
  data() {
    return {
		buscar:{
			marca : 0,
			kilo : 0,
			raza : 0,

		},
		numeroComida: 0,
		servicios: {},
    }
  },
  mounted() {
	console.log("COMIDA")
	console.log(this.comidaPerro.kilos);
	this.comidaPerro.kilos.forEach(comida => console.log(comida));
	console.log("COMIDA")

	this.servicios = this.serviciosCategoria;


	if(this.categoria.nombre == "Comida de Perros"){
		this.numeroComida = this.categoria.id;
	}
	console.log("COMIDA DE PERRO")
    console.log("this.serviciosCategoria ===> ", this.serviciosCategoria);
	console.log(this.numeroComida)
	console.log("COMIDA DE PERRO")
  },
  methods: {
  	Back() {
  		modalController.dismiss();
  	},
	  async ModalDetalleServicio(servicio) {
		  console.log('[MODAL SERVICIOS] detalle serivicio')
		  console.log(servicio)
		  console.log('[MODAL SERVICIOS] detalle serivicio')
		const modal = await modalController
		  .create({
			component: UnServicioModal,
			componentProps: {
			  title: 'New Title',
			  servicio: servicio,
			}
		  })
		return modal.present();
	  },
	  Filtrar(){
		

			this.servicios = this.serviciosCategoria

			if(this.buscar.marca != 0){
				this.servicios = this.servicios.filter(servicio => servicio.comidadeperro.marca_id == this.buscar.marca );
				
			}

			if(this.buscar.kilo != 0){
				this.servicios = this.servicios.filter(servicio => servicio.comidadeperro.kilos_id == this.buscar.kilo );
			}

			if(this.buscar.raza != 0){
				this.servicios = this.servicios.filter(servicio => servicio.comidadeperro.raza_id == this.buscar.raza );
			}




	  },
	  Borrarfiltro(){
		this.servicios = this.serviciosCategoria
		this.buscar.marca = 0
		this.buscar.kilo = 0
		this.buscar.raza = 0
	  }
  }
};