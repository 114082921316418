import { IonPage } from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';

import { 
	IonContent, 
	IonHeader, 
	IonTitle, 
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	modalController,
	IonMenuButton,
	IonLabel,
	IonItemDivider,
	IonList,
	IonSelectOption,
	IonSelect,
	IonItem,
	IonTextarea,
	IonDatetime,
	IonThumbnail,
	IonImg,
	IonRippleEffect,
  IonToggle,
  toastController,
} from '@ionic/vue';
import { Terminos } from 'vue';
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins
import { 
  exitOutline,
  chevronBackOutline,
  checkmark,
  ban
} from 'ionicons/icons';
import { stringifyQuery } from 'vue-router';
export default {
  name: 'pagar-modal',
  components: { 
  	IonContent, 
  	IonHeader, 
  	IonTitle, 
  	IonToolbar,
  	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	ExploreContainer, 
    IonMenuButton,
    IonPage,
    IonLabel,
    IonItemDivider,
    IonList,
    IonSelectOption,
    IonSelect,
    IonItem,
    IonTextarea,
    IonDatetime,
    IonThumbnail,
    IonImg,
    IonRippleEffect,
    IonToggle,
  },
  props: {
    title: { 
    	type: String, 
    	default: 'Super Modal' 
    },
    servicio: {
      type: Object,
      default: {}
    }
  },
  setup() {
  	return {
  		chevronBackOutline,
		checkmark,
		ban
    };
  },
  data() {
    return {
    minimo :'',
    maximo :'',
    hora :'',
    dayValues :'',
    anio :'',
    meses :'',
    dia :'',
		solicitud:{},
    fecha: new Date().toISOString(),
    bloque:{
      manana:false,
      tarde:false,
    },
    mostrar_btn_submit: true,
    mostrar_btn_cargando: false
    }
  },
  mounted() {
	
    console.log(this.servicio)
	  console.log(this.solicitud)
    // console.log('[PAGE PAGAR] fecha')
    // this.fecha = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDay();
    // console.log(new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDay())
    console.log('[PAGE PAGAR] fecha')

    if(this.servicio.manana == 1){
      this.bloque.manana = true;
    }
    if(this.servicio.tarde == 1){
      this.bloque.tarde = true;
    }

    this.minimo = this.servicio.jornada_inicio 
    this.maximo = this.servicio.jornada_fin 
    
    var fecha =new Date().toISOString().split("T");
    var mes_calculo = this.fecha.split("-");


    console.log(fecha);
    this.solicitud.anio =mes_calculo[0];
    this.anio =mes_calculo[0];
    var año = mes_calculo[0];
    var mes = mes_calculo[1];
    var indices = [];
    var diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    var arreglo = this.servicio.disponibilidad.split(",");
    arreglo.forEach(element => {

          if(element.trim() === "Lun."){
            element = "Lunes";
          }
          if(element.trim() === "Mar."){
            element = "Martes";
          }
          if(element.trim() == "Mié."){
            element = "Miércoles";
          }
          if(element.trim() == "Jue."){
            element = "Jueves";
          }
          if(element.trim() == "Vie."){
            element = "Viernes";
          }  
          if(element.trim() == "Sáb"){
            element = "Sábado";
          }
          var idx = diasSemana.indexOf(element);
          indices.push(idx);

    });
    var diasMes = new Date(año, mes, 0).getDate();
    indices.forEach(element => {

      for (var dia = 1; dia <= diasMes; dia++) {
        // Ojo, hay que restarle 1 para obtener el mes correcto
        var indice = new Date(año, mes - 1, dia).getDay();
        
        if(diasSemana[indice] == diasSemana[element]){

          this.dayValues += dia + ",";
        }

      }
  });
  this.dayValues= this.dayValues.substring(0, this.dayValues.length - 1);
  console.log(this.dayValues);
  console.log(this.servicio.disponibilidad)
  
  },
  methods: {
  	Back() {
  		modalController.dismiss();
  	},async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 2000,
        position: 'top',
      })
      return toast.present();
    },
	Pagar(data) {
        var formData = new window.FormData();
        console.log('Pagar');
        console.log("id_solicitud->"+data.id);
        console.log("id_Servicio->"+data.servicio_id);
        formData.append(
          "idSolicitud",
          data.id
        );       
        formData.append(
          "id",
          data.servicio_id
        );
        formData.append(
          "idUsuario",
          localStorage.getItem('_cap_usuario_id')
        );
            
        window.http.post(
          process.env.VUE_APP_URL + "/api/pagos/flow-pagar-solicitud", 
          formData,
          window.headers
        ).then(
          res => {
            Storage.set({
              key: 'pago',
              value: true
            });
            window.location=res.data.redirect;

            this.mostrar_btn_submit = true;
            this.mostrar_btn_cargando = false;
          }
        ).catch(
          err => {
            if (err.status === 500) {
              this.status = err.data.status
            }

            if (err.status === 422) {
              this.errors = err.data.errors
            }

            if (err.status === 401) {
              this.errors = err.data.errors
            }
            console.log(err)

            this.openToast("Se ha producido un error al intentar pagar.");

            this.mostrar_btn_submit = true;
            this.mostrar_btn_cargando = false;
          }
        );
      },
  traerdia(mes){
    try {
        this.dayValues ='';      
        const mes_ingresado = mes.target.value;
        console.log("mes_ingresado", mes_ingresado);
        var fecha_nueva = mes_ingresado.split("T");
        var mes_a_calcular = fecha_nueva[0].split("-");


        var anio = mes_a_calcular[0];
        var mes = mes_a_calcular[1];
        var i = Number(mes);
   
        var indices = [];
        var diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

        var arreglo = this.servicio.disponibilidad.split(",");
        arreglo.forEach(element => {
    
              if(element.trim() === "Lun."){
                element = "Lunes";
              }
              if(element.trim() === "Mar."){
                element = "Martes";
              }
              if(element.trim() == "Mié."){
                element = "Miércoles";
              }
              if(element.trim() == "Jue."){
                element = "Jueves";
              }
              if(element.trim() == "Vie."){
                element = "Viernes";
              }  
              if(element.trim() == "Sáb"){
                element = "Sábado";
              }
              var idx = diasSemana.indexOf(element);
              indices.push(idx);
    
        });
  
        var diasMes = new Date(anio, i, 0).getDate();

        indices.forEach(element => {
    
          for (var dia = 1; dia <= diasMes; dia++) {
            // Ojo, hay que restarle 1 para obtener el mes correcto
            var indice = new Date(anio, mes-1, dia).getDay();
            
            if(diasSemana[indice] == diasSemana[element]){
    
              this.dayValues += dia + ",";
            }
    
          }
      });
      this.dayValues= this.dayValues.substring(0, this.dayValues.length - 1);
      var arreglo = this.dayValues.split(",");
      arreglo.sort((unNumero, otroNumero) => unNumero - otroNumero);
      var arr_2 = arreglo.join(",");
      this.dayValues = arr_2;
    } catch (error) {
      console.error("Se ha producido un error en traerdia => " + error);
    }
  },
	Solicitar(e) {
    e.preventDefault();
        var formData = new window.FormData();

      console.log(this.solicitud);
      let bloque = 1;
      if(this.solicitud.manana){
        bloque = 1;
      }else{
        bloque = 2;
      }

      try{
        this.solicitud.anio = this.solicitud.anio.slice(0, 4);
       }catch(error){
        //Si hay error, se debe a que el mes y dia ya están con el formato correspondiente
       }

       console.log("ANIO", this.solicitud.anio);
       console.log("MESES", this.solicitud.meses);
       console.log("DIAS", this.solicitud.dia);

       try{
        this.solicitud.meses = this.solicitud.meses.split("T");
        this.solicitud.meses = this.solicitud.meses[0].split("-");
       }catch(error){
        //Si hay error, se debe a que el mes y dia ya están con el formato correspondiente
       }

       try{
        this.solicitud.dia = this.solicitud.dia.split("T");
        this.solicitud.dia = this.solicitud.dia[0].split("-");
       }catch(error){
        //Si hay error, se debe a que el mes y dia ya están con el formato correspondiente
       }

       console.log("ANIO FILTRADO", this.solicitud.anio);
       console.log("MESES FILTRADO", this.solicitud.meses);
       console.log("DIAS FILTRADO", this.solicitud.dia);

       this.solicitud.fecha_programacion = this.solicitud.anio+"-"+this.solicitud.meses[1]+"-"+this.solicitud.dia[2]
       if(this.solicitud.manana == false && this.solicitud.tarde == false){
        this.openToast("El campo bloque de la fecha es requerido");
       } else if(this.solicitud.manana == true && this.solicitud.tarde == true){
        this.openToast("Se debe seleccionar solamente un bloque");
        } else if (this.solicitud.fecha_programacion == null) {
          this.openToast("El campo fecha es requerido");
        } else if (this.solicitud.hora == null) {
          this.openToast("El campo hora  es requerido");
        } else {

          console.log('solicitar');
           formData.append(
             "usuario_id",
             localStorage.getItem('_cap_usuario_id')
           );
            
           var aux_hora = this.solicitud.hora.split("T");
           var hora = aux_hora[1].split(".")[0];

   
           formData.append(
             "fecha_programacion",
             this.solicitud.fecha_programacion + " " + hora
           );
   
           formData.append(
             "servicio_id",
             this.servicio.id
           );
   
           formData.append(
             "extra",
             this.solicitud.extra
           );
           formData.append(
            "bloque",
            bloque
          );
   
          this.mostrar_btn_submit = false;
          this.mostrar_btn_cargando = true;

           window.http.post(
             process.env.VUE_APP_URL + "/api/pagar-solicitud", 
             formData,
             window.headers
           ).then(
             res => {
              if(res.data.exito == false)
              {
                this.openToast(res.data.msg);
                this.mostrar_btn_submit = true;
                this.mostrar_btn_cargando = false;
              }
              else
                this.Pagar(res.data)
             }
           ).catch(
             err => {
               if (err.status === 500) {
                 this.status = err.data.status
               }
   
               if (err.status === 422) {
                 this.errors = err.data.errors
               }
   
               if (err.status === 401) {
                 this.errors = err.data.errors
               }
               console.log(err)
             }
           );
        }
      },
  }
};