<ion-page>
	<ion-header>
		<ion-toolbar>
			
			<ion-buttons slot="start">
				<ion-icon slot="icon-only" :icon="timeOutline" style="color: white;"></ion-icon>
				<h5  class="titulo-head">Urgencias</h5>
		    </ion-buttons>
			<ion-buttons slot="end">
		        <ion-button class="btn-exit" @click="Logout">
			        <ion-icon slot="icon-only" :icon="exitOutline"></ion-icon>
			    </ion-button>
		    </ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content :fullscreen="true">
		<div class="contenido-home">
			<div class="cuerpo" >
			    <ion-list>
					<div v-for="(cate,itemObjKey) in categorias">
						<ion-ripple-effect type="unbounded"></ion-ripple-effect>
						<ion-item button @click="getSubCategorias(cate)" lines="none" class="item-servicios card"  style="padding-left: 2px; padding-right: 2px; margin-top: 2px; margin-bottom: 2px;">		
					    	<img :src="cate.imagen">
							<div class="elem-item info">
						    	<ion-label>
									<p style="color: black; font-size: 0.9em; text-transform: uppercase; padding: 20px 0px;"><b>{{ cate.nombre }}</b></p>
								</ion-label>
						    </div>
					    </ion-item>
					</div>
				</ion-list>
			</div>
		</div>
		
	</ion-content>
</ion-page>
