import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';
import eventBus from '../../../eventBus.js';

import { 
  exitOutline,
  chevronBackOutline,
  checkboxOutline,
} from 'ionicons/icons';
import SolicitudModal from '../../modals/solicitudes/index.vue';
import { 
  IonButtons, 
  IonMenuButton,
  IonLabel,
  IonCol,
  IonRow,
  IonCardContent,
  IonCard,
  IonItemDivider,
  IonList,
  IonSelectOption,
  IonSelect,
  IonItem,
  IonTextarea,
  IonDatetime,
  toastController,
  IonThumbnail,
  IonImg,
  IonRippleEffect,
  IonButton,
  IonIcon,
  modalController
} from '@ionic/vue';

export default  {
  name: 'realizadas',
  components: { 
    ExploreContainer, 
    IonMenuButton,
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent, 
    IonPage,
    IonButtons,
    IonIcon,
    IonLabel,
    IonCol,
    IonRow,
    IonCardContent,
    IonCard,
    IonItemDivider,
    IonList,
    IonSelectOption,
    IonSelect,
    IonItem,
    IonTextarea,
    IonDatetime,
    IonThumbnail,
    IonImg,
    IonRippleEffect,
    IonButton,
    IonIcon,
  },
  setup() {
    const items = [
      {
        'text': 'Mascotas',
        'src': '/assets/icon/iconlist.jpg'
      }, {
        'text': 'Limpieza',
        'src': '/assets/icon/iconlist.jpg'
      }, {
        'text': 'Jardin y exterior',
        'src': '/assets/icon/iconlist.jpg'
      }, {
        'text': 'Repuestos',
        'src': '/assets/icon/iconlist.jpg'
      }, {
        'text': 'Comestibles y bebestibles',
        'src': '/assets/icon/iconlist.jpg'
      }, {
        'text': 'Otros',
        'src': '/assets/icon/iconlist.jpg'
      }
    ];

    return {
      exitOutline,
      items,
      checkboxOutline,
      chevronBackOutline
    }
  },
  data () {
    return {
      status: {},
      errors: {},
      flag_form: true,
      solicitud: {},
      servicios: {},
      solicitudes: {},
      metodo_de_pagos: {},
      estados_sols: {},
      pagos: {},
      volver: true,
      valoraciones_cliente:{},
      valoracion_cliente: 0,
    }
  },
  mounted() {
    

    this.getSolicitudes();
    //console.log('[PAGE SOLICITUDES] solicitudes')
    //console.log(this.solicitudes)
    //console.log('[PAGE SOLICITUDES] solicitudes')
    //this.getServicios();     
    eventBus.$on('update-solicitudes-realizadas-proveedor', () => {
      console.log("eventBus -> update-solicitudes-realizadas-proveedor");
      this.getSolicitudes();
    });
  },
    methods: {
      async ModalDetalleSolicitud(servicio,solicitud) {

        this.valoracion_cliente = 0;

     
          this.valoraciones_cliente.forEach(valoracion => {
            
              if(solicitud.id == valoracion.solicitud_id){
                this.valoracion_cliente = valoracion.calificacion;
              }
          });

        console.log("VALORACIONES")
        console.log(this.valoracion_cliente)
        console.log("VALORACIONES")

        const modal = await modalController
          .create({
            component: SolicitudModal,
            componentProps: {
              servicio:servicio,
              solicitud: solicitud,
              terminada: true,
              valoracion_cliente: this.valoracion_cliente,
            }
          })
        return modal.present();
      },
      
      Back() {
        this.volver = false
        this.flag_form = true

      },
      doRefresh(event){
        setTimeout(() => {
          console.log('Async operation has ended');
          this.getSolicitudes();
          event.target.complete();
        }, 2000);
      },
      async openToast(msg) {
          const toast = await toastController.create({
            message: msg,
            duration: 2000,
            position: 'top',
          })
          return toast.present();
      },

      getServicios() {
        var formData = new window.FormData();
        window.http.get(
          process.env.VUE_APP_URL + "/api/servicios", 
          formData,
          window.headers
        ).then(
          res => {
            this.servicios = res.data.servicios
            this.solicitudes = res.data.solicitudes
          
            console.log("[PAGE REALIZADAS] GET servicios-solicitudes")
            console.log(this.servicios)
            console.log(this.solicitudes)
            console.log("[PAGE REALIZADAS] GET servicios-solicitudes")
          }
        ).catch(
          err => {
            if (err.status === 500) {
              this.status = err.data.status
            }

            if (err.status === 422) {
              this.errors = err.data.errors
            }

            if (err.status === 401) {
              this.errors = err.data.errors
            }
            console.log(err)
          }
        );
      },

      getSolicitudes() {
        var formData = new window.FormData();
        window.http.get(
          process.env.VUE_APP_URL + "/api/solicitudes/get/proveedor/terminadas/" + localStorage.getItem('_cap_usuario_id'), 
          formData,
          window.headers
        ).then(
          res => {
            this.servicios = res.data.servicios
            this.solicitudes = res.data.solicitudes
            this.valoraciones_cliente = res.data.valoracion_cliente
            this.valoracion_realizada_por_cliente = res.data.valoracion_realizada_por_cliente
            console.log("[PAGE REALIZADAS] GET servicios-solicitudes")
            console.log(res.data)
            console.log("[PAGE REALIZADAS] GET servicios-solicitudes")
          }
        ).catch(
          err => {
            if (err.status === 500) {
              this.status = err.data.status
            }

            if (err.status === 422) {
              this.errors = err.data.errors
            }

            if (err.status === 401) {
              this.errors = err.data.errors
            }
            console.log(err)
          }
        );
      },

      ShowFormSol() {
        this.flag_form = !this.flag_form;
      },
 
      Logout(e) {
        e.preventDefault();

        var formData = new window.FormData();

        window.http.post(
          process.env.VUE_APP_URL + "/api/logout", 
          formData,
          window.headers
        ).then(
          res => {
            console.log(res)
            if (res.data.exito == true) {
              localStorage.removeItem('_cap_tok_code');
              localStorage.removeItem('_cap_usuario_id');
              localStorage.removeItem('_cap_rol');
              this.$router.replace({ path: '/inicio' })
            } else {
              this.openToast(res.data.msg)
            }
          }
        ).catch(
          err => {
            if (err.status === 500) {
              this.status = err.data.status
            }

            if (err.status === 422) {
              this.errors = err.data.errors
            }

            if (err.status === 401) {
              this.errors = err.data.errors
            }
            console.log(err)
          }
        );
      }

  }
};


