<ion-page>
    <ion-content :fullscreen="true">
        <div class="principal-content">
            <div class="login-logo mas">
                <img class="logo" src="/assets/logo/logo.png" alt="Ionic logo">
              </div>
         <ion-label class="texto-exito" v-if="respuesta_exito" style="/*color: rgb(84, 255, 96);*/font-weight: bold;text-align: center;">Ha tenido exito en su compra, redireccionaremos a sus solicitudes.</ion-label>
         <ion-label class="texto-exito" v-if="!respuesta_exito" style="color: rgb(255, 54, 54);font-weight: bold;text-align: center;">Se ha producido un error el realizar la compra, por favor vuelva a intentarlo.</ion-label>
       </div>
</ion-content>
</ion-page>