export default class Usuario {
	constructor(
  		id_login=null,
        usuario=null,
        activo=null,
        is_super_admin=null,
        ultimo_ingreso=null,
        password=null,
        fecha_creacion=null,
        fecha_modificacion=null,
        token
  	) {
		this.id_login 			= id_login 
        this.usuario 			= usuario
        this.activo 			= activo
        this.is_super_admin 	= is_super_admin
        this.ultimo_ingreso 	= ultimo_ingreso
        this.password 			= password
        this.fecha_creacion 	= fecha_creacion
        this.fecha_modificacion = fecha_modificacion
        this.token 				= token
	}
}