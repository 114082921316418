<ion-page>
	<ion-header>
		<ion-toolbar>
			
			<ion-buttons slot="start">
				<ion-icon slot="icon-only" :icon="checkboxOutline" style="color: white;"></ion-icon>
				<h5  class="titulo-head">Solicitudes</h5>
		    </ion-buttons>
			<ion-buttons slot="end">
		        <ion-button class="btn-exit" @click="Logout">
			        <ion-icon slot="icon-only" :icon="exitOutline"></ion-icon>
			    </ion-button>
		    </ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content :fullscreen="true">
		<div class="contenido-home">
			<div class="cuerpo">
				<ion-item-divider>
					<ion-label>Completadas</ion-label>
					<ion-label style="float:right;position:absolute;width:100%;text-align: right;padding-right:35px;color: red;font-style: italic;"
					v-if="cantidad_sin_calificar != 0"
					>
						{{cantidad_sin_calificar}} Sin Calificar
					</ion-label>
				</ion-item-divider>
				<ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
					<ion-refresher-content>
					</ion-refresher-content>
				</ion-refresher>
				<ion-list>
					<div v-for="(servicio,itemObjKey) in servicios">
						<ion-ripple-effect type="unbounded"></ion-ripple-effect>
						<ion-item button @click="ModalDetalleSolicitud(servicio,solicitudes[itemObjKey],valoracion_atencion[itemObjKey],valoracion_rapidez[itemObjKey],valoracion_valor[itemObjKey])" lines="none" class="item-servicios" style="padding-left: 2px; padding-right: 2px; margin-top: 2px; margin-bottom: 2px;">		
							<div class="elem-item" style="padding-right: 2em;">
								<ion-thumbnail slot="start">
									<ion-img class="icon-serv" :src="servicio.categoria.imagen"></ion-img>
								</ion-thumbnail>
							</div>
							<div class="elem-item">
								<ion-label>
									<p style="color: black; font-size: 0.8em;"><b>{{ servicio.nombre }} </b></p>
									<p>{{ solicitudes[itemObjKey].fecha_programacion }} - {{ solicitudes[itemObjKey].estado.nombre }}
									</p>
									<p>
										{{ valoracion_valor[itemObjKey] == '-' ? 'Sin Calificar' : 'Calificada' }}
									</p>
								</ion-label>
							</div>
						</ion-item>
					</div>
				</ion-list>
			</div>
		</div>
	</ion-content>
</ion-page>