import { 
	IonContent, 
	IonHeader, 
	IonTitle, 
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	modalController
} from '@ionic/vue';
import { Terminos } from 'vue';
import { 
  exitOutline,
  chevronBackOutline
} from 'ionicons/icons';
export default {
  name: 'terminos-modal',
  components: { 
  	IonContent, 
  	IonHeader, 
  	IonTitle, 
  	IonToolbar,
  	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent
  },
  props: {
    title: { 
    	type: String, 
    	default: 'Super Modal' 
    },
  },
  setup() {
  	return [
  		chevronBackOutline
  	];
  },
  data() {
    return {
      content: " Lorem Ipsum  Lorem Ipsum  Lorem Ipsum  Lorem Ipsum  Lorem Ipsum  Lorem Ipsum  Lorem Ipsum  Lorem Ipsum  Lorem Ipsum  Lorem Ipsum ",
    }
  },
  methods: {
  	Back() {
  		modalController.dismiss();
  	}
  }
};