<ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-button class="btn-exit" @click="Back">
					<ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-buttons slot="start">
				<h5 class="titulo-head">Detalle servicio</h5>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content :fullscreen="true">
		<div class="contenido-home">
			<div class="cuerpo">
				<ion-row responsive-lg>
			        <ion-col>
						<ion-card class="card_form_config_serv">
			    	<ion-item-divider>
				      	<ion-label>Formulario Servicio</ion-label>
				    </ion-item-divider>
					<form @submit.prevent="ConfigurarServicio">
						<ion-row responsive-lg>
							<ion-col size="12" class="col-sep">
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
										<ion-input
										  v-model="editarServicio.nombre"
							              placeholder="editarServicio"
							              name="nombre" 
							              inputmode="text" 
										  
							              required>
							            </ion-input>
							        </ion-item>
									</ion-col>
								</ion-row>
	
								<ion-row responsive-lg>
									<!-- <ion-col size="6" class="col-sep">
										<ion-item>
											<ion-select 
												v-model="editarServicio.tipo_servicio_id"
												id="tipo_servicio"
												required 
												aria-required="required"
												placeholder="Tipo Servicio">
												<ion-select-option 
													v-for="tipo in tipos_servicios" 
													:value="tipo.id">
														{{ tipo.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col> -->
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-select v-model="editarServicio.categoria.id"  placeholder="Categoría">
												<ion-select-option v-for="categoria in categorias" :value="categoria.id">
													{{ categoria.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep" v-if="editarServicio.categoria.id == categoriaComida">
										<ion-item>
											<ion-select v-model="editarServicio.comidadeperro.marca_id"  placeholder="Marca">
												<ion-select-option v-for="marca in marcas" :value="marca.id">
													{{ marca.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep" v-if="editarServicio.categoria.id == categoriaComida">
										<ion-item>
											<ion-select v-model="editarServicio.comidadeperro.kilos_id"  placeholder="Kilo">
												<ion-select-option v-for="kilo in kilos" :value="kilo.id">
													{{ kilo.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep" v-if="editarServicio.categoria.id == categoriaComida">
										<ion-item>
											<ion-select v-model="editarServicio.comidadeperro.raza_id"  placeholder="Raza">
												<ion-select-option v-for="raza in razas" :value="raza.id">
													{{ raza.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
								</ion-row>
							
								<ion-row responsive-lg>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-select mutliple="false" @ionChange="ObtenerComunas($event)" v-model="editarServicio.zona_id" placeholder="Zona">
												<ion-select-option v-for="zona in zonas" :key="zona.id" :value="zona.id" :selected="editarServicio.zona_id === zona.id ">
													{{ zona.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-select required mutliple="false" v-model="editarServicio.subzona_id" placeholder="Subzona">
												<ion-select-option v-for="subzona in subzonas" :key="subzona.id"  :value="subzona.id" :selected="editarServicio.subzona_id === subzona.id ">
													{{ subzona.nombre }}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
								</ion-row>

								<ion-row responsive-lg>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-input
										  	  v-model="editarServicio.valor" 
								              placeholder="Valor" 
								              name="valor" 
								              type="number" 
								              required>
								            </ion-input>
										</ion-item>
									</ion-col>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-select v-model="editarServicio.estado_id"  placeholder="Estado">
												<ion-select-option v-for="estado in estados_servicio" :value="estado.id">{{ estado.nombre }}</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
								</ion-row>

					            <ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-input
											v-model="editarServicio.descripcion" 
											placeholder="Descripción" 
											name="descripcion" 
											type="text" 
											required>
											</ion-input>
										</ion-item>
									</ion-col>
								</ion-row>
								<br>
					        	<ion-item-divider class="classdias">
							      	<ion-label>Horarios</ion-label>
							    </ion-item-divider>
							    <ion-row responsive-lg>
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-datetime required v-model="editarServicio.jornada_inicio" placeholder="Inicio" display-format="HH:mm"></ion-datetime>
										</ion-item>
									</ion-col>
	
									<ion-col size="6" class="col-sep">
										<ion-item>
											<ion-datetime required v-model="editarServicio.jornada_fin"  placeholder="Termino" display-format="HH:mm"></ion-datetime>
										</ion-item>
									</ion-col>
								</ion-row>
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-input
											v-model="editarServicio.duracion_servicio"
											placeholder="Duracion"
											name="duracion_servicio" 
											type="number" maxlength="3"
											required>
									</ion-input><ion-label>Minutos</ion-label>
							        </ion-item>
									</ion-col>
								</ion-row>
							<!--	<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Mañana</ion-label>
											<ion-toggle placeholder="Mañana" v-model="editarServicio.manana" @ionChange="servicio.manana = editarServicio.manana"></ion-toggle>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Tarde</ion-label>
											<ion-toggle placeholder="Tarde"  v-model="editarServicio.tarde" @ionChange="servicio.tarde = editarServicio.tarde"></ion-toggle>
										</ion-item>
									</ion-col>
								</ion-row>-->
								<br>
								<ion-item-divider class="classdias">
							      	<ion-label>Días disponibles</ion-label>
							    </ion-item-divider>
					            <ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Lunes</ion-label>
											<ion-toggle placeholder="Lunes" v-model="editarServicio.lunes" @ionChange="servicio.lunes = editarServicio.lunes"></ion-toggle>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Martes</ion-label>
											<ion-toggle placeholder="Martes"  v-model="editarServicio.martes" @ionChange="servicio.martes = editarServicio.martes"></ion-toggle>
										</ion-item>
									</ion-col>
								</ion-row>
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Miércoles</ion-label>
											<ion-toggle placeholder="Miércoles" v-model="editarServicio.miercoles" @ionChange="servicio.miercoles = editarServicio.miercoles"></ion-toggle>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Jueves</ion-label>
											<ion-toggle placeholder="Jueves"   v-model="editarServicio.jueves" @ionChange="servicio.jueves = editarServicio.jueves"></ion-toggle>
										</ion-item>
									</ion-col>
								</ion-row>
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Viernes</ion-label>
											<ion-toggle placeholder="Viernes"  v-model="editarServicio.viernes" @ionChange="servicio.viernes = editarServicio.viernes"></ion-toggle>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Sábado</ion-label>
											<ion-toggle placeholder="Sábado"  v-model="editarServicio.sabado" @ionChange="servicio.sabado = editarServicio.sabado"></ion-toggle>
										</ion-item>
									</ion-col>
								</ion-row>
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item>
											<ion-label>Domingo</ion-label>
											<ion-toggle placeholder="Domingo" v-model="editarServicio.domingo" @ionChange="servicio.domingo = editarServicio.domingo" ></ion-toggle>
										</ion-item>
									</ion-col>
								</ion-row> 
							</ion-col>
						</ion-row>
						<ion-row>
				          <ion-col>
				              <ion-button type="submit" expand="block">
				              	GUARDAR CAMBIOS
				              </ion-button>
				          </ion-col>
				        </ion-row>
					</form>
				</ion-card>
					</ion-col>
				</ion-row>
			</div>
		</div>
	</ion-content>
</ion-page>
