import ExploreContainer from '@/components/ExploreContainer.vue';
import { 
	IonContent, 
	IonHeader, 
	IonTitle, 
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	modalController,
	IonMenuButton, 
	IonInput,
	IonPage,  
	IonItemDivider,
	IonToggle,
	IonLabel,
	IonRippleEffect,
	IonImg,
	toastController,
	IonList,
	IonSelect,
	IonDatetime,
	IonItem,
	IonSelectOption,
} from '@ionic/vue';
import { Terminos } from 'vue';
import { 
	person,
	call,
	pin,
	exitOutline,
	chevronBackOutline,
	checkmark,
	ban
} from 'ionicons/icons';

import UnServicioModal from '../../modals/servicio/index.vue';
import eventBus from '../../../eventBus.js';

export default {
  name: 'servicios-modal',
  components: { 
  	IonContent, 
  	IonHeader, 
  	IonTitle, 
  	IonToolbar,
  	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	ExploreContainer, 
	IonPage,
	IonMenuButton,
	IonInput,
	IonItemDivider,
	IonToggle,
	IonLabel,
	IonRippleEffect,
	IonImg,
	IonList,
	IonSelect,
	IonDatetime,
	IonItem,
	IonSelectOption,
  },
  props: {
    solicitud: {
      type: Object,
      default: {}
    },
	servicio: {
		type: Object,
      default: {}
	},
	valoracion_valor: {
		type: Object,
      default: {}
	},
	valoracion_rapidez: {
		type: Object,
      default: {}
	},
	valoracion_atencion: {
		type: Object,
      default: {}
	},
	terminada: {
		type: Object,
		default: {}
	}
  },
  setup() {
  	return {
		person,
		call,
		pin,
  		chevronBackOutline,
		checkmark,
		ban
    };
  },
  data() {
    return {
		estados_solitudes: {},
		estrella: {},
		colorBackground: {},
		mostrarEstrellas: false,
    }
  },
  mounted() {
	this.ObtenerEstadoSolicitud()
	console.log('[MODAL SOLICITUDES] this solicitud-serviio')
    console.log(this.solicitud)
	console.log(this.servicio)
	console.log(this.valoracion_proveedor)
	console.log(this.valoracion_servicio)
	console.log('[MODAL SOLICITUDES] this solicitud-serviio')

	if(this.valoracion_valor != '-' ){
		this.mostrarEstrellas = true;
	}

	if(this.solicitud.estado.id == 1){
		this.colorBackground = {
			'background-color': '#4DBFAD',
		}
	}else if(this.solicitud.estado.id == 2){
		this.colorBackground = {
			'background-color': '#FFDAA3',
		}
	}else{
		this.colorBackground = {
			'background-color': '#F27B6F',
		}
	}
  },
  methods: {
  	Back() {
  		modalController.dismiss();
  	},async openToast(msg) {
		const toast = await toastController.create({
			message: msg,
			duration: 2000,
			position: 'top',
		})
		return toast.present();
	},
	ObtenerEstadoSolicitud() {
		  var formData = new window.FormData();
		window.http.get(
			process.env.VUE_APP_URL + "/api/solicitud/estados", 
			formData,
			window.headers
		).then(
			res => {
				console.log("[MODAL SOLICITUDES] obtener tipos servicios")
				this.estados_solitudes = res.data
				console.log(this.estados_solitudes)
				console.log("[MODAL SOLICITUDES] obtener tipos servicios")
			}
		).catch(
			err => {
				if (err.status === 500) {
					this.status = err.data.status
				}

				if (err.status === 422) {
					this.errors = err.data.errors
				}

				if (err.status === 401) {
					this.errors = err.data.errors
				}
				console.log(err)
			}
		);
	  },
	  ConfigurarSolicitudEstado(e) {
		e.preventDefault();

		
			var formData = new window.FormData();
			
			formData.append(
				'estado_id', 
				this.solicitud.estado.id
			);
			formData.append(
				'solicitud_id', 
				this.solicitud.id
			);
			window.http.post(
				process.env.VUE_APP_URL + "/api/solicitud/cambio-estado", 
				formData,
				window.headers
			).then(
				res => {
					if (res.data.exito == true) {
						this.openToast(res.data.msg);
					} else {
						this.openToast(res.data.msg);
					}
					this.Back()
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},

		async SolicitarOtra() {
			console.log('[MODAL SERVICIOS] detalle serivicio')
			console.log()
			console.log('[MODAL SERVICIOS] detalle serivicio')
		  const modal = await modalController
			.create({
			  component: UnServicioModal,
			  componentProps: {
				title: 'New Title',
				servicio: this.servicio,
			  }
			})
		  return modal.present();
		},

		Calificar(e){
			e.preventDefault();
			var formData = new window.FormData();
			
			if (!this.estrella.clasificacion_atencion) {
				this.openToast("Selecciona una clasificacion de por la atención");
				}else if (!this.estrella.clasificacion_valor) {
					this.openToast("Selecciona una clasificacion de por el valor");
			}else if (!this.estrella.clasificacion_rapidez) {
					this.openToast("Selecciona una clasificacion de por la rapidez");
				
			}else{
				console.log(this.solicitud.id);
				formData.append(
					'solicitud_id', 
					this.solicitud.id
				);
				formData.append(
					'servicio_id', 
					this.servicio.id
				);
				formData.append(
					'calificacion_atencion', 
					this.estrella.clasificacion_atencion
				);
				formData.append(
					'calificacion_valor', 
					this.estrella.clasificacion_valor
				);
				formData.append(
					'calificacion_rapidez', 
					this.estrella.clasificacion_rapidez
				);
				formData.append(
					'comentario', 
					this.estrella.comentarios
				);
				formData.append(
					'usuario_id', 
					
					this.solicitud.usuario_id
				);
				formData.append(
					'proveedor_id', 
					this.servicio.usuario_id
				);
				window.http.post(
					process.env.VUE_APP_URL + "/api/valoraciones/valorar", 
					formData,
					window.headers
				).then(
					res => {
						if (res.data.exito == true) {
							this.openToast(res.data.msg);
							eventBus.$emit('update-solicitudes-realizadas-cliente');
						} else {
							this.openToast(res.data.msg);
						}
						this.Back()
					}
				).catch(
					err => {
						if (err.status === 500) {
							this.status = err.data.status
						}
	
						if (err.status === 422) {
							this.errors = err.data.errors
						}
	
						if (err.status === 401) {
							this.errors = err.data.errors
						}
						console.log(err)
					}
				);
			}

		}
  }
};