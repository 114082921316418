import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

var axios = require('axios');
var FormData = require('form-data');
axios.defaults.headers.head = {
	'Access-Control-Allow-Origin': "*",
	'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
	'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
	"Access-Control-Allow-Credentials": "true",
	"Authorization": ""
}

axios.defaults.headers = {
	'Access-Control-Allow-Origin': "*",
	'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
	'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
	"Access-Control-Allow-Credentials": "true",
	"Authorization": ""
}
axios.defaults.headers.common = {
	'Access-Control-Allow-Origin': "*",
	'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
	'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
	"Access-Control-Allow-Credentials": "true",
	"Authorization": ""
}
window.http = axios
window.FormData = FormData

window.headers = {
	headers: {
		'Authorization': "Bearer ",
		"usuario_id": -1
	}
}

axios.interceptors.response.use(
	function (response) {
		var token = window.localStorage.getItem("_cap_tok_code");
		var usuario_id = window.localStorage.getItem("_cap_usuario_id");
	    window.headers.headers["Authorization"] =  "Bearer "+token;
	    window.usuario_id =  usuario_id;
	    console.log(window.headers);
    	return response;
	}, 
	function (error) {		
	    console.log(error)
	    return Promise.reject(error);
	}
);
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/core.css';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as $ from 'jquery'
window.$ = $

const app = createApp(App)
  .use(IonicVue)
  .use(router);
  
router.isReady().then(() => {
  app.mount('#app');
});

defineCustomElements(window);  