export class Api {
	constructor() {}

	Ejm() {
		return "holas";
	}


	getCategorias() {
		var formData = new window.FormData();
		window.http.post(
			process.env.VUE_APP_URL + "/api/categorias", 
			formData,
			window.headers
		).then(
			res => {
				console.log(res)
				if (res.data.exito == true) {
					localStorage.removeItem('_cap_tok_code');
					this.$router.replace({ path: '/inicio' })
				} else {
					this.openToast(res.data.msg)
				}
			}
		).catch(
			err => {
				if (err.status === 500) {
					this.status = err.data.status
				}

				if (err.status === 422) {
					this.errors = err.data.errors
				}

				if (err.status === 401) {
					this.errors = err.data.errors
				}
				console.log(err)
			}
		);
	}
}