<ion-page>
	<ion-content :fullscreen="true">
		<div class="content">
			<div class="login-logo mas">
				<img class="logo" src="/assets/logo/logo.png" alt="Ionic logo">
			</div>

			<form @submit.prevent="RecuperarContrasena" class="form-login">
				<ion-list>
					<ion-row responsive-lg>
						<ion-col>
							<ion-item class="ion-no-padding">
								<ion-input :v-model="username" name="username" id="username" type="text"
									spellcheck="false" autocapitalize="off" placeholder="Correo electrónico" required
									aria-required="required">
								</ion-input>
							</ion-item>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col>
							<ion-button type="submit" expand="block">
								Enviar
							</ion-button>
						</ion-col>
					</ion-row>
				</ion-list>
			</form>
		</div>
		<ExploreContainer name="Tab 3 page" />
	</ion-content>
</ion-page>
<!--
<ion-page>
  <ion-header>
    <ion-toolbar>
      <ion-title>Tab 1</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content :fullscreen="true">
    <ExploreContainer name="Tab 1 page" />
  </ion-content>
</ion-page>

-->