import { 
	IonContent, 
	IonHeader, 
	IonTitle, 
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	modalController,
	IonPage,
		IonList,
		IonItem,
		IonItemDivider,
		IonLabel,
		IonRippleEffect,
		IonImg,
		IonThumbnail,
		
		IonSelectOption,
		IonSelect,
} from '@ionic/vue';
import { Terminos } from 'vue';
import ServicioModal from '../../modals/servicios/index.vue';
import UnServicioModal from '../../modals/servicio/index.vue';

import { 
  exitOutline,
  chevronBackOutline,
  checkmark,
  ban
} from 'ionicons/icons';
export default {
  name: 'servicios-modal',
  components: { 
  	IonContent, 
  	IonHeader, 
  	IonTitle, 
  	IonToolbar,
  	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	IonPage,
		IonList,
		IonItem,
		IonItemDivider,
		IonLabel,
		IonRippleEffect,
		IonImg,
		IonThumbnail,
		
		IonSelectOption,
		IonSelect,
  },
  props: {
    title: { 
    	type: String, 
    	default: 'Super Modal' 
    },
    subcategorias: {
      type: Object,
      default: {}
    },
	servicios: {
		type: Object,
		default: {}
	},
	cantidadServicios: {
		type: Object,
		default: {}
	},
	categoria: {
		type: Object,
		default: {}
	},
	bloque: {
		type: Object,
		default: {}
	},
	proveedores: {
		type: Object,
		default: {}
	}
  },
  setup() {
  	return {
  		chevronBackOutline,
		checkmark,
		ban
    };
  },
  data() {
    return {
		existeSubCategorias: false,
		existenServicios: false,
		serviciosCategoria:{},
		serviciosArreglo:{},
		categoriasArreglo:{},
		cantidadArreglo:{},
		buscar:{
			proveedor: 0
		},
    }
  },
  mounted() {
	console.log('[ MODAL SUBCATEGORIAS ]categoriaaas')
	console.log(this.proveedores)
	console.log('[ MODAL SUBCATEGORIAS ]categoriaaas')
	this.serviciosArreglo  = this.servicios
	this.categoriasArreglo = this.subcategorias
	this.cantidadArreglo = this.cantidadServicios


	if(this.categoriasArreglo.length > 0){
		this.existeSubCategorias = true;
	}
	if(this.serviciosArreglo.length > 0){
		this.existenServicios = true;
	}
  },
  methods: {
  	Back() {
  		modalController.dismiss();
  	},
	Filtrar(e){
		e.preventDefault();
		var formData = new window.FormData();
		
			
		formData.append(
			"id_padre",
			this.categoria.id
		);
		
		formData.append(
			"bloque",
			this.bloque
		);

		console.log("BUSCAR PROVEEDRO")
		console.log(this.buscar.proveedor);
		console.log("BUSCAR PROVEEDRO")
		if(this.buscar.proveedor != 0){
			formData.append(
				"proveedor_id",
				this.buscar.proveedor
			);
		}
			
		window.http.post(
			process.env.VUE_APP_URL + "/api/subcategorias", 
			formData,
			window.headers
		).then(
			res => {
			this.categoriasArreglo = res.data.categorias
			this.serviciosArreglo = res.data.servicios
			this.cantidadArreglo = res.data.cantidad
			console.log('[PAGE CATEGORIAS] servicios')
			console.log(this.categoriasArreglo)
			console.log(this.serviciosArreglo)
			console.log(this.cantidadArreglo)
			console.log('[PAGE CATEGORIAS] servicios')
			this.existeSubCategorias = false;
			if(this.categoriasArreglo.length > 0){
				this.existeSubCategorias = true;
			}
			this.existenServicios = false;
			if(this.serviciosArreglo.length > 0){
				this.existenServicios = true;
			}
			
			}
		).catch(
			err => {
			if (err.status === 500) {
				this.status = err.data.status
			}

			if (err.status === 422) {
				this.errors = err.data.errors
			}

			if (err.status === 401) {
				this.errors = err.data.errors
			}
			console.log(err)
			}
		);
	},
	BorrarFiltro(){
		console.log("Borrar Filtro")
		this.buscar.proveedor = 0;
		this.serviciosArreglo  = this.servicios
		this.categoriasArreglo = this.subcategorias
		this.cantidadArreglo = this.cantidadServicios
		this.existeSubCategorias = false;
			if(this.categoriasArreglo.length > 0){
				this.existeSubCategorias = true;
			}
			this.existenServicios = false;
			if(this.serviciosArreglo.length > 0){
				this.existenServicios = true;
			}
	},
	getServiciosCategorias(categoria){
		var formData = new window.FormData();
	
		console.log('[MODAL SUBCATEGORIAS] id categoria')
		console.log(categoria.id)
		console.log('[MODAL SUBCATEGORIAS] id categoria')
		formData.append(
		  "id_categoria",
		  categoria.id
		);
		formData.append(
			"bloque",
			this.bloque
		  );
		if(this.buscar.proveedor != 0){
			formData.append(
				"proveedor_id",
				this.buscar.proveedor
			);
		}
			
		window.http.post(
		  process.env.VUE_APP_URL + "/api/servicios/categoria", 
		  formData,
		  window.headers
		).then(
		  res => {
			  console.log('[MODAL SUBCATEGORIAS] servicioss')
			  console.log(res.data)
				console.log('[MODAL SUBCATEGORIAS] servicioss')
				this.serviciosCategoria=res.data.servicios;
				
				this.marcas = res.data.marcas
				this.kilos = res.data.kilos
				this.razas = res.data.razas
			this.ModalServiciosSubCategoria(this.serviciosCategoria,categoria)
		  }
		).catch(
		  err => {
			if (err.status === 500) {
			  this.status = err.data.status
			}

			if (err.status === 422) {
			  this.errors = err.data.errors
			}

			if (err.status === 401) {
			  this.errors = err.data.errors
			}
			console.log(err)
		  }
		);
	},
	  async ModalServiciosSubCategoria(serviciosCategoria,categoria) {
		let comidaPerro = {"marcas":this.marcas,"kilos":this.kilos,"razas":this.razas};
		console.log('[MODAL SUBCATEGORIAS] modal servicios')
		console.log(comidaPerro)

		console.log('[MODAL SUBCATEGORIAS] modal servicios')
	  const modal = await modalController
		.create({
		  component: ServicioModal,
		  componentProps: {
			title: 'New Title',
			serviciosCategoria: serviciosCategoria,
			categoria: categoria,
			bloque: this.bloque,
			comidaPerro: comidaPerro,
		  }
		})
	  return modal.present();
	},
	async ModalDetalleServicio(servicio) {
		console.log('[MODAL SERVICIOS] detalle serivicio')
		console.log(servicio)
		console.log('[MODAL SERVICIOS] detalle serivicio')
	  const modal = await modalController
		.create({
		  component: UnServicioModal,
		  componentProps: {
			title: 'New Title',
			servicio: servicio,
		  }
		})
	  return modal.present();
	},
  }
};