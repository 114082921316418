<ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
		        <ion-button class="btn-exit" @click="Back">
			        <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
			    </ion-button>
		    </ion-buttons>
			<ion-buttons slot="start">
				<h5 class="titulo-head">Terminos y condiciones</h5>
		    </ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content>
		<div class="contenido-home">
			<ion-row responsive-lg>
		        <ion-col>
			        <ion-card class="card-terminos">
			            <ion-card-content>
							<ion-row>
								<ion-col class="cont-terminos">
									{{ content }}
								</ion-col>
							</ion-row>
				    		<ion-row>
								<ion-col>
									<ion-button 
									  @click="Back()"
									  expand="block">
										Cerrar
									</ion-button>
								</ion-col>
							</ion-row>
					    </ion-card-content>
				    </ion-card>
				</ion-col>
			</ion-row>
		</div>
	</ion-content>
</ion-page>