import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonRippleEffect } from '@ionic/vue';
import {
	IonButtons,
	IonMenuButton,
	IonList,
	IonItem,
	IonLabel,
	IonThumbnail,
	IonImg,
	IonButton,
	IonIcon,
	alertController,
	IonInput,
	IonToggle,
	IonDatetime,
	IonSelect,
	IonSelectOption,
	IonItemDivider,
	IonCard,
	IonCardContent,
	IonRow,
	IonCol,
	toastController,
	modalController
} from '@ionic/vue';

import {
	Api
} from "../../../servicios/Api.service"
import EditarServicio from '../../modals/editarservicio/index.vue';
import {
	exitOutline,
	cogOutline,
	chevronBackOutline
} from 'ionicons/icons';
import ExploreContainer from '@/components/ExploreContainer.vue';

export default {
	name: 'configurar',
	components: {
		ExploreContainer,
		IonHeader,
		IonToolbar,
		IonTitle,
		IonContent,
		IonPage,
		IonButtons,
		IonMenuButton,
		IonRippleEffect,
		IonList,
		IonItem,
		IonLabel,
		IonThumbnail,
		IonImg,
		IonButton,
		IonIcon,
		IonInput,
		IonToggle,
		IonDatetime,
		IonSelect,
		IonSelectOption,
		IonItemDivider,
		IonCard,
		IonCardContent,
		IonRow,
		IonCol
	},
	setup() {
		const items = [
			{
				'text': 'Mascotas',
				'src': '/assets/icon/iconlist.jpg'
			}, {
				'text': 'Limpieza',
				'src': '/assets/icon/iconlist.jpg'
			}, {
				'text': 'Jardin y exterior',
				'src': '/assets/icon/iconlist.jpg'
			}, {
				'text': 'Repuestos',
				'src': '/assets/icon/iconlist.jpg'
			}, {
				'text': 'Comestibles y bebestibles',
				'src': '/assets/icon/iconlist.jpg'
			}, {
				'text': 'Otros',
				'src': '/assets/icon/iconlist.jpg'
			}
		];

		var apiserv = new Api();

		return {
			exitOutline,
			cogOutline,
			apiserv,
			items,
			chevronBackOutline
		}
	},
	data() {
		return {
			status: {},
			errors: {},
			categoriasT: [],
			volver: false,
			flag_form: true,
			flag_servicios: true,
			tipos_servicios: [],
			servicio: {},
			categorias: {},
			estados_servicio: {},
			servicio: {
				nombre: "",
				lunes: false,
				martes: false,
				miercoles: false,
				jueves: false,
				viernes: false,
				sabado: false,
				domingo: false,
				manana: false,
				tarde: false,
			},
			zonas: {},
			subzonas: {},
			nombre: "",
			servicios: {},
			comunasTodas: {},
			categoriaComida: 0
		}
	},
	mounted() {
		


		this.ObtenerTipoServicios();
		this.ObtenerCategorias();
		this.ObtenerEstadoServicio();
		this.ObtenerRegiones();
		this.ObtenerTodasComunas();
		this.getServicios();

		var d = new Date();
  		d.setHours(12,0,0);
		d = d.getHours()
		  console.log('DATE');
		  console.log(d);
		  console.log('DATE');



	},

	methods: {


		FormServicio(categoria_id) {
			this.flag_form = true
			this.flag_servicios = false
		},

		async openToast(msg) {
			const toast = await toastController.create({
				message: msg,
				duration: 2000,
				position: 'top',
			})
			return toast.present();
		},

		async presentAlertConfirm() {
			const alert = await alertController.create({
				header: "My Header",
				message:
					"this is my message.",
				cssClass: "sel-comunas",
				buttons: [
					{
						text: "Cancel",
						role: "cancel",
						cssClass: "secondary",
						handler: blah => {
							// Do something
						}
					},
					{
						text: "Sync",
						handler: () => {
							// Do something
						}
					}
				]
			});
			await alert.present();
		},

		ChangeDias(event) {
			console.log(this.servicio);
		},

		ShowFormServ() {
			this.flag_form = !this.flag_form
			this.volver = false
		},

		ObtenerCategorias() {
			console.log("CATEGORIA MASCOTAS")
			var formData = new window.FormData();
			window.http.get(
				process.env.VUE_APP_URL + "/api/categorias-todas",
				formData,
				window.headers
			).then(
				res => {
					this.categorias = res.data.categorias
					this.categoriasEditar = res.data.categorias

					this.marcas = res.data.marcas
					this.kilos = res.data.kilos
					this.razas = res.data.razas

					this.categorias.forEach(categoria => categoria.nombre == "Comida de Perros" ? this.categoriaComida = categoria.id : "")
					console.log("CATEGORIA MASCOTAS")
					console.log(this.categoriaComida);
					console.log("CATEGORIA MASCOTAS")
					console.log('[PAGE CONFIGURAR] api categorias')
					console.log(this.categorias)
					console.log(this.categoriasEditar)
					console.log('[PAGE CONFIGURAR] api categorias')
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},

		Regiones(event) {
			this.ObtenerComunas(zona_id);
		},

		ObtenerTipoServicios() {
			var formData = new window.FormData();
			window.http.post(
				process.env.VUE_APP_URL + "/api/tipo/servicios/obtener",
				formData,
				window.headers
			).then(
				res => {
					console.log(res.data)
					this.tipos_servicios = res.data
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},

		ObtenerEstadoServicio() {
			var formData = new window.FormData();
			window.http.get(
				process.env.VUE_APP_URL + "/api/estado/servicio",
				formData,
				window.headers
			).then(
				res => {
					this.estados_servicio = res.data
					console.log(res.data)
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},

		ObtenerRegiones() {
			
			var formData = new window.FormData();

			window.http.get(
				process.env.VUE_APP_URL + "/api/zonas/get",
				formData,
				window.headers
			).then(
				res => {
					this.zonas = res.data
					console.log(res.data)
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},


		ObtenerTodasComunas() {
			var formData = new window.FormData();

			window.http.get(
				process.env.VUE_APP_URL + "/api/subzonas/get",
				formData,
				window.headers
			).then(
				res => {
					this.subzonasTodas = res.data
					console.log(res.data)
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},

		ObtenerComunas(select) {
			console.log(select.target.value);
			var formData = new window.FormData();

			window.http.get(
				process.env.VUE_APP_URL + "/api/subzonas/getbyzona/" + select.target.value,
				formData,
				window.headers
			).then(
				res => {
					this.subzonas = res.data
					console.log(res.data)
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},

		Back() {
			this.volver = false
			this.flag_form = true
			this.flag_servicios = true
			this.categorias = this.categoriasT
			this.ObtenerCategorias()
		},

		getServicios() {

			console.log("cargaron los servicios");

			var formData = new window.FormData();


			// formData.append(
			//   "id",
			//   localStorage.getItem('_cap_usuario_id')
			// );

			window.http.get(
				process.env.VUE_APP_URL + "/api/servicios/get/usuario/" + localStorage.getItem('_cap_usuario_id'),
				formData,
				window.headers
			).then(
				res => {
					console.log(res);
					this.servicios = res.data.info
					console.log("[PROVEEDOR] obtener servicios")
					console.log(this.servicios)
					console.log("[PROVEEDOR] obtener servicios")
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		},

		ConvertBoolToInt(data) {
			if (data == true) {
				return 1;
			} else {
				return 0;
			}
		},

		ConfigurarServicio(e) {
			e.preventDefault();

			if (this.servicio.nombre == null) {
				this.openToast("Debes elegir un nombre");
			} else if (this.servicio.categoria_id == null) {
				this.openToast("Debes elegir una categoría");
			} else if (this.servicio.zonas == null) {
				this.openToast("Debes ingresar una zona");
			} else if (this.servicio.subzonas == null) {
				this.openToast("Debes ingresar una subzona");
			} else if (this.servicio.valor == null) {
				this.openToast("Debes añadir un valor en pesos chilenos (CLP)");
			} else if (this.servicio.estado_id == null) {
				this.openToast("Debes elegir un estado");
			} else if (this.servicio.inicio == null) {
				this.openToast("Debes selecionar un horario de inicio");
			} else if (this.servicio.termino == null) {
				this.openToast("Debes seleccionar un horario de término");
			} else if (this.servicio.descripcion == null) {
				this.openToast("Debes añadir una descripción");
			}else {
				
				

				let errores = false;
				let mensaje = "";
				if (this.servicio.categoria_id == this.categoriaComida){
					if(this.servicio.marca_id == null){
						errores = true;
						mensaje = "El campo marca es requerido";
					}else if (this.servicio.kilo_id == null){
						errores = true;
						mensaje = "El campo kilo es requerido";
					}else if (this.servicio.raza_id == null){
						errores = true;
						mensaje = "El campo raza es requerido";
					}
				}
				if(errores ){
					this.openToast(mensaje);
				}else{
					var formData = new window.FormData();
					
					if (this.servicio.categoria_id == this.categoriaComida){

						formData.append(
							'marca',
							this.servicio.marca_id
						);
						formData.append(
							'kilo',
							this.servicio.kilo_id
						);
						formData.append(
							'raza',
							this.servicio.raza_id
						);
					}
					var horaInicio = this.servicio.inicio.split("T")[1].split(".")[0];
				var horaTermino = this.servicio.termino.split("T")[1].split(".")[0];
				formData.append(
					'jornada_inicio',
					horaInicio
				);
				formData.append(
					'jornada_fin',
					horaTermino
				);
				formData.append(
					'categoria_id',
					this.servicio.categoria_id
				);
				formData.append(
					'subzona_id',
					this.servicio.subzonas
				);	

				formData.append(
					'zona_id',
					this.servicio.zonas
				);
				formData.append(
					'descripcion',
					this.servicio.descripcion
				);
				formData.append(
					'estado_id',
					this.servicio.estado_id
				);
				formData.append(
					'nombre',
					this.servicio.nombre
				);
				formData.append(
					'tipo_servicio_id',
					1
				);
				formData.append(
					'usuario_id',
					window.usuario_id
				);
				formData.append(
					'valor',
					parseInt(this.servicio.valor, 10)
				);

				formData.append(
					'manana',
					this.ConvertBoolToInt(this.servicio.manana)
				);
				formData.append(
					'tarde',
					this.ConvertBoolToInt(this.servicio.tarde)
				);

				formData.append(
					'lunes',
					this.ConvertBoolToInt(this.servicio.lunes)
				);
				formData.append(
					'martes',
					this.ConvertBoolToInt(this.servicio.martes)
				);
				formData.append(
					'miercoles',
					this.ConvertBoolToInt(this.servicio.miercoles)
				);
				formData.append(
					'jueves',
					this.ConvertBoolToInt(this.servicio.jueves)
				);
				formData.append(
					'viernes',
					this.ConvertBoolToInt(this.servicio.viernes)
				);
				formData.append(
					'sabado',
					this.ConvertBoolToInt(this.servicio.sabado)
				);
				formData.append(
					'domingo',
					this.ConvertBoolToInt(this.servicio.domingo)
				);
				formData.append(
					'duracion',
					this.servicio.duracion_servicio
				);
				window.http.post(
					process.env.VUE_APP_URL + "/api/servicios/crear",
					formData,
					window.headers
				).then(
					res => {
						console.log(res)
						if (res.data.exito == true) {
							this.getServicios();
							this.openToast(res.data.msg);
							this.Back();

							this.servicio = {
								nombre: "",
								lunes: false,
								martes: false,
								miercoles: false,
								jueves: false,
								viernes: false,
								sabado: false,
								domingo: false
							};
						} else {
							this.openToast(res.data.msg);
						}

					}
				).catch(
					err => {
						if (err.status === 500) {
							this.status = err.data.status
						}

						if (err.status === 422) {
							this.errors = err.data.errors
						}

						if (err.status === 401) {
							this.errors = err.data.errors
						}
						console.log(err)
					}
				);
				}

				
			}
			console.log(this.servicio)
		},
		async ModalEditarServicio(servicio) {
			console.log('[PAGE SERVICIOS] modal EDITAR servicio --->')
			console.log(servicio)
			console.log(this.zonas)

			const modal = await modalController
				.create({
					component: EditarServicio,
					componentProps: {
						title: 'New Title',
						editarServicio: servicio,
						categorias: this.categoriasEditar,
						tipos_servicios: this.tipos_servicios,
						estados_servicio: this.estados_servicio,
						zonas: this.zonas,
						subzonas: this.subzonasTodas,
						marcas: this.marcas,
						kilos:  this.kilos,
						razas: this.razas,
						categoriaComida: this.categoriaComida

					}
				})
			return modal.present();
		},

		Logout(e) {
			e.preventDefault();

			var formData = new window.FormData();

			window.http.post(
				process.env.VUE_APP_URL + "/api/logout",
				formData,
				window.headers
			).then(
				res => {
					console.log(res)
					if (res.data.exito == true) {
						localStorage.removeItem('_cap_tok_code');
						localStorage.removeItem('_cap_usuario_id');
						localStorage.removeItem('_cap_rol');
						this.$router.replace({ path: '/inicio' })
					} else {
						this.openToast(res.data.msg)
					}
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		}
	}
};