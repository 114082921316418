import ExploreContainer from '@/components/ExploreContainer.vue';
import { 
	IonContent, 
	IonHeader, 
	IonTitle, 
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	modalController,
	IonMenuButton, 
	IonInput,
	IonPage,  
	IonItemDivider,
	IonToggle,
	IonLabel,
	IonRippleEffect,
	IonImg,
	toastController,
	IonList,
	IonSelect,
	IonDatetime,
	IonItem,
	IonSelectOption,
} from '@ionic/vue';
import { 
	camera, 
	trash, 
	close, 
} from 'ionicons/icons';
import { usePhotoGallery, Photo } from '../../../_helpers/usePhotoGallery.js';
import { Terminos } from 'vue';
import { 
	exitOutline,
	chevronBackOutline,
	checkmark,
	ban
} from 'ionicons/icons';
export default {
  name: 'servicios-modal',
  components: { 
  	IonContent, 
  	IonHeader, 
  	IonTitle, 
  	IonToolbar,
  	IonButtons,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	ExploreContainer, 
	IonPage,
	IonMenuButton,
	IonInput,
	IonItemDivider,
	IonToggle,
	IonLabel,
	IonRippleEffect,
	IonImg,
	IonList,
	IonSelect,
	IonDatetime,
	IonItem,
	IonSelectOption,
  },
  props: {
    categoria: {
      type: Object,
      default: {}
    },
  },
  setup() {
	var { 
		takePhoto, 
		photos, 
		showActionSheet 
	} = usePhotoGallery();
  	return {
  		chevronBackOutline,
		checkmark,
		ban,
		takePhoto, 
		photos, 
		showActionSheet,
		camera, 
		trash, 
		close,
		exitOutline,
		chevronBackOutline
    };
  },
  data() {
    return {
		urgencia: {},
		imagen_2:  "/assets/perfil/img_placeholder.png",
		imagen_1:  "/assets/perfil/img_placeholder.png",
		foto_base64_1: "/assets/perfil/img_placeholder.png",
		foto_base64_2: "/assets/perfil/img_placeholder.png",
    }
  },
  mounted() {
	
	console.log('[MODAL URGENCIAS] this solicitud-serviio')
    console.log(this.categoria)
	console.log('[MODAL URGENCIAS] this solicitud-serviio')

	
  },
  methods: {
  	Back() {
  		modalController.dismiss();
  	},async openToast(msg) {
		const toast = await toastController.create({
			message: msg,
			duration: 2000,
			position: 'top',
		})
		return toast.present();
	},
	TomarFoto(img) {
		this.takePhoto().then( 
			url => {
				this.photos = window.photos
				this.imagen_1 = this.photos.webviewPath
				this.foto_base64_1 = window.fotoperfil
			}
		);
		console.log(img)
	},
	TomarFoto2(img) {
		this.takePhoto().then( 
			url => {
				this.photos = window.photos
				this.imagen_2 = this.photos.webviewPath
				this.foto_base64_2 = window.fotoperfil
			}
		);
		console.log(img)
	},
	EnviarUrgencia(e){
		e.preventDefault();
		var formData = new window.FormData();
		
		console.log('[CATEGORIA]');
		console.log(this.categoria);
		console.log('[CATEGORIA]');
		if (false) {

		}else{
			
			formData.append(
				'descripcion', 
				this.urgencia.descripcion
			);
			formData.append(
				'imagen_1', 
				this.foto_base64_1
			);
			formData.append(
				'imagen_2', 
				this.foto_base64_2
			);
			formData.append(
				"cliente_id",
				localStorage.getItem('_cap_usuario_id')
			);
			formData.append(
				'categoria_id', 
				this.categoria.id
			);
			
			window.http.post(
				process.env.VUE_APP_URL + "/api/urgencias/crear", 
				formData,
				window.headers
			).then(
				res => {
					if (res.data.exito == true) {
						this.openToast('Urgencia creada con exito!');
					} else {
						this.openToast('Urgencia creada con exito!');
					}
					this.Back()
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.status = err.data.status
					}

					if (err.status === 422) {
						this.errors = err.data.errors
					}

					if (err.status === 401) {
						this.errors = err.data.errors
					}
					console.log(err)
				}
			);
		}
	}
  }
};