<ion-page>
	<ion-header>
		<ion-toolbar>
			
			<ion-buttons slot="start">
				<ion-icon slot="icon-only" :icon="personCircleOutline" style="color: white;"></ion-icon>
				<h5  class="titulo-head">Perfil</h5>
		    </ion-buttons>
			<ion-buttons slot="end">
		        <ion-button class="btn-exit" @click="Logout">
			        <ion-icon slot="icon-only" :icon="exitOutline"></ion-icon>
			    </ion-button>
		    </ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content :fullscreen="true">
		<div class="contenido-home">
			<div class="cab-prefil">
				<div class="foto-perfil ion-activatable ripple-parent">
        			<ion-ripple-effect type="unbounded"></ion-ripple-effect>
        			<!--<ion-img :src="foto_perfil"   @click="CambiarFoto(this)"></ion-img>-->

        			<img v-bind:src="foto_perfil" @click="CambiarFoto(this)">
				</div>
			</div>
			<div class="cuerpo">
				<ion-card>
				<form @submit.prevent="Editar" class="form-login">
				    <ion-list>
				      <ion-row responsive-lg>
				        <ion-col>
				          <ion-item class="ion-no-padding">
				            <ion-input
				              v-model="usuario.usuario"
				              name="usuario"
				              type="text"
				              spellcheck="false"
				              autocapitalize="off"
				              placeholder="Correo electrónico"
				              required
				              :disabled="flag_form"
				            ></ion-input>
				          </ion-item>
				        </ion-col>
				      </ion-row>

				      <ion-row responsive-lg>
				        <ion-col size="10">
				          <ion-item class="ion-no-padding">
				            <ion-input 
				              v-model="usuario.rut" 
				              placeholder="RUT" 
				              name="rut" 
				              type="number" 
				              maxlength="8"
				              required
				              :disabled="flag_form">
				            </ion-input>
				          </ion-item>
				        </ion-col>
						
				        <ion-col size="2">
				          <ion-item class="ion-no-padding">
				            <ion-input 
				              v-model="usuario.dv" 
				              placeholder="Dv" 
				              name="dv"
				              maxlength="1" 
				              type="text" 
				              required
				              :disabled="flag_form">
				            </ion-input>
				          </ion-item>
				        </ion-col>
				      </ion-row>
<br>
				      <ion-row responsive-lg>
				        <ion-col size="6">
				          <ion-item class="ion-no-padding">
				            <ion-input
				              v-model="usuario.nombres"
				              name="nombres"
				              type="text"
				              autocapitalize="off"
				              placeholder="Nombres"
				              required
				              :disabled="flag_form"
				            ></ion-input>
				          </ion-item>
				        </ion-col>

				        <ion-col size="6">
				          <ion-item class="ion-no-padding">
				            <ion-input
				              v-model="usuario.apellidos"
				              name="apellidos"
				              type="text"
				              spellcheck="false"
				              autocapitalize="off"
				              placeholder="Apellidos"
				              required
				              :disabled="flag_form"
				            ></ion-input>
				          </ion-item>
				        </ion-col>
				      </ion-row>

				      <ion-row responsive-lg>
				        <ion-col size="12">
				          <ion-item class="ion-no-padding">
				            <ion-datetime 
				              v-model="usuario.fecha_nacimiento"
				              :value="usuario.fecha_nacimiento"
				              placeholder="Fecha de nacimiento" 
				              display-timezone="utc"
				              monthShortNames="Ene, Feb, Mar, Abril, Mayo, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
				              displayFormat="DD de MMM, del YYYY"
				              :disabled="flag_form" >
				            </ion-datetime>
				          </ion-item>
				        </ion-col>
				      </ion-row>

				      <ion-row responsive-lg>
				        <ion-col size="6">
				          <ion-item class="ion-no-padding">
				            <ion-input 
				              v-model="usuario.telefono1" 
				              placeholder="Teléfono"
				              name="telefono1" 
				              type="number" 
				              required
				              :disabled="flag_form">
				            </ion-input>
				          </ion-item>
				        </ion-col>

				        <ion-col size="6">
				          <ion-item class="ion-no-padding">
				            <ion-input 
				              v-model="usuario.telefono2" 
				              placeholder="Otro teléfono"
				              name="telefono2" 
				              type="number"
				              :disabled="flag_form" 
				              >
				            </ion-input>
				          </ion-item>
				        </ion-col>
				      </ion-row>

				      <ion-row responsive-lg>
				        <ion-col size="6">
				          <ion-item class="ion-no-padding">
				            <ion-select :disabled="flag_form" required @ionChange="ObtenerComunas($event)" :value="usuario.zona_id" v-model="usuario.zona_id" placeholder="Zona">
				              <ion-select-option v-for="zona in zonas" :value="zona.id" :selected="usuario.zona_id === zona.id ">
				                {{ zona.nombre }}
				              </ion-select-option>
				            </ion-select>
				          </ion-item>
				        </ion-col>
				        <ion-col size="6">
				          <ion-item class="ion-no-padding">
				            <ion-select :disabled="flag_form" v-model="usuario.subzona_id" :value="usuario.subzona_id"  placeholder="Subzona">
				              <ion-select-option v-for="subzona in subzonas" :value="subzona.id" :selected="usuario.subzona_id === subzona.id ">
				                {{ subzona.nombre }}
				              </ion-select-option>
				            </ion-select>
				          </ion-item>
				        </ion-col>
				      </ion-row>

				      <ion-row responsive-lg>
				        <ion-col size="12">
				          <ion-item class="ion-no-padding">
				            <ion-input 
				              v-model="usuario.direccion" 
				              placeholder="Dirección" 
				              name="direccion" 
				              type="text" 
				              required
				              :disabled="flag_form">
				            </ion-input>
				          </ion-item>
				        </ion-col>
						<ion-col size="12">
							<ion-item class="ion-no-padding">
							  <ion-input v-model="usuario.numero" placeholder="Número" name="numero" type="number" :disabled="flag_form" required>
							  </ion-input>
							</ion-item>
						  </ion-col>
				        <ion-col size="12">
				          <ion-item class="ion-no-padding">
				            <ion-input 
				              v-model="usuario.departamento"
				              placeholder="Complemento (Opcional): Número dept, condominio, oficina, piso, etc" 
				              name="departamento" 
				              type="text" 
				              :disabled="flag_form">
				            </ion-input>
				          </ion-item>
				        </ion-col>
				      </ion-row>
				      <br>
				      <ion-row responsive-lg>
				        <ion-col>
				          	<div v-if="!flag_form" class="cont-col">
								<ion-button class="btn-ops btn2" color="plomito" @click="flag_form = !flag_form" size="small">Cancelar</ion-button>
								<ion-button class="btn-ops" type="submit" size="small">Guardar</ion-button>
							</div>
							<div v-else class="cont-col">
								<ion-button @click="flag_form = !flag_form" class="btn-ops" type="submit" size="small">
									Modificar
								</ion-button>
							</div>
				        </ion-col>
				      </ion-row>
				    </ion-list>
				  </form>
				</ion-card>
			</div>
		</div>
	</ion-content>
</ion-page>