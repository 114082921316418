<ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-button class="btn-exit" @click="Back">
					<ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-buttons slot="start">
				<h5 class="titulo-head">{{ servicio.nombre }}</h5>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<div class="cuerpo">
		<ion-row responsive-lg>
			<ion-col>
				<ion-card class="card_form_config_serv">
					<ion-item-divider>
						  <ion-label>Formulario de Pago</ion-label>
					</ion-item-divider>
					<form @submit.prevent="Solicitar">
						
						<br>
						<ion-row responsive-lg>
							<ion-col size="12" class="col-sep">
								<ion-item>
									<ion-textarea 
									  clear-on-edit="true"
									  v-model="solicitud.extra"
									  placeholder="Comentarios al proveedor" 
									  name="extra" 
									  inputmode="text" 
									  required>
									</ion-textarea>
								</ion-item>
							</ion-col>
						</ion-row>
						<br>
						<ion-row responsive-md>
							<ion-col size="2">
								<div><ion-datetime 
									v-model="solicitud.anio"
									placeholder="Año"
									value ="2022"
									display-timezone="utc"
									:day-short-names="customDayShortNames"
									displayFormat="YYYY "
									presentation="date"
									size = "cover"
									>
									</ion-datetime></div>
							  </ion-col>
							  <ion-col size="2" offset="3">
								<div>	<ion-datetime v-model="solicitud.meses"
									placeholder="Mes"
									display-timezone="utc"
									monthShortNames="Ene, Feb, Mar, Abril, Mayo, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
									displayFormat="MMM "
									@ionChange ="traerdia($event)"
									>
									</ion-datetime></div>
							  </ion-col>
							  <ion-col size="2" offset="3">
								<div><ion-datetime 
									v-model="solicitud.dia"
									placeholder="Dia"
									display-timezone="utc"
									displayFormat="DD"
									presentation="date"
									:dayValues ="dayValues"
									size = "cover"
									>
									</ion-datetime></div>
							  </ion-col>
						</ion-row>
						<ion-row responsive-lg>
							<ion-col size="12" class="col-sep">
								<ion-datetime presentation="date"></ion-datetime>
								<ion-item>
									<ion-datetime v-model="solicitud.hora" placeholder="Hora Solicitud" displayFormat="HH:mm " :min ="minimo" :max ="maximo" minuteValues="0,15,30,45"></ion-datetime>
								</ion-item>
							</ion-col>
						</ion-row>
						<br>
						<!--<ion-row responsive-lg>
							<ion-col size="12" class="col-sep" v-if="bloque.manana">
								<ion-item>
									<ion-label>Mañana</ion-label>
									<ion-toggle placeholder="Mañana" v-model="solicitud.manana"  @ionChange="solicitud.manana = !solicitud.manana"></ion-toggle>
								</ion-item>
							</ion-col>
							<ion-col size="12" class="col-sep" v-if="bloque.tarde">
								<ion-item>
									<ion-label>Tarde</ion-label>
									<ion-toggle placeholder="Tarde" v-model="solicitud.tarde" @ionChange="solicitud.tarde = !solicitud.tarde"></ion-toggle>
								</ion-item>
							</ion-col>
						</ion-row>-->
						<br>
						<ion-row>
							<ion-col>
								<ion-button type="submit" expand="block" v-if="mostrar_btn_submit">Pagar</ion-button>
                <ion-button type="button" expand="block" id="btn_cargando" v-if="mostrar_btn_cargando">Cargando...</ion-button>
 
							</ion-col>
						</ion-row>
					</form>
				</ion-card>
			</ion-col>
		</ion-row>
	</div>	
</ion-page> 