<ion-page>
  <ion-tabs>
    <ion-tab-bar slot="bottom">

      <ion-tab-button tab="urgencias" href="/home-cliente/urgencias">
        <ion-icon :icon="timeOutline" />
        <ion-label>Urgencias</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="configurar" href="/home-cliente/configurar">
        <ion-icon :icon="cogOutline" />
        <ion-label>Servicios</ion-label>
      </ion-tab-button>
        
      <ion-tab-button tab="solicitudes" href="/home-cliente/solicitudes" @click="updateSolicitudes()">
        <ion-icon :icon="newspaper" />
        <ion-label>Solicitudes</ion-label>
      </ion-tab-button>
      
      <ion-tab-button tab="realizadas" href="/home-cliente/realizadas" @click="updateRealizadas()">
        <ion-icon :icon="checkboxOutline" />
        <ion-label>Realizadas</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="perfiles" href="/home-cliente/perfiles">
        <ion-icon :icon="personCircleOutline" />
        <ion-label>Perfil</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-page>