<ion-page>
	<ion-tabs>
		<ion-tab-bar slot="bottom">
			<ion-tab-button tab="tab1" href="/inicio/login">
				<ion-label>Iniciar sesión</ion-label>
			</ion-tab-button>

			<ion-tab-button tab="tab2" href="/inicio/registro">
				<ion-label>Registro</ion-label>
			</ion-tab-button>

			<ion-tab-button tab="tab3" href="/inicio/recuperar">
				<ion-label>Recuperar Contraseña</ion-label>
			</ion-tab-button>
		</ion-tab-bar>
	</ion-tabs>
</ion-page>