<ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-button class="btn-exit" @click="Back">
					<ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-buttons slot="start">
				<h5 class="titulo-head">{{categoria.nombre}} </h5>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content :fullscreen="true">
		<div class="contenido-home">
			
			    <ion-item-divider>
			      	<ion-label><p style="color: white;">{{ categoria.descripcion }} </p>  </ion-label>
			    </ion-item-divider>

				<ion-row responsive-lg>
			        <ion-col>
						<div  style="margin: 20px 10px;">
							<form @submit.prevent="Filtrar" >
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item class="filtro">
											<ion-label>
												Prestador :
											</ion-label>
											<ion-select v-model="buscar.proveedor"  placeholder="Buscar">
												<ion-select-option :value="0">
													Todos
												</ion-select-option>
												<ion-select-option v-for="proveedor in proveedores" :value="proveedor.usuario_id">
													{{proveedor.empresa}}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col  size="6" class="col-sep">
										<ion-button class="boton-crear-principal" type="submit" >Filtrar</ion-button>
									</ion-col>
									<ion-col  size="6" class="col-sep">
										<ion-button class="boton-crear-principal" v-on:click="BorrarFiltro" type="button" >Borrar Filtro</ion-button>
									</ion-col>
								</ion-row>
							</form>
						</div>
			        </ion-col>
			    </ion-row>
			<div class="cuerpo" >
			    <ion-item-divider>
			      	<ion-label><h2>Sub Categorías disponibles</h2></ion-label>
			    </ion-item-divider>
			    <ion-list v-if="existeSubCategorias">
					<div v-for="(subc,itemObjKey) in categoriasArreglo">
						<ion-ripple-effect type="unbounded"></ion-ripple-effect>
						<ion-item button @click="getServiciosCategorias(subc)" lines="none" class="item-servicios" >	
					    	<div class="elem-item" style="padding-right: 2em;">
					    		<ion-thumbnail slot="start">
						        	<ion-img class="icon-subc" :src="subc.imagen"></ion-img>
						    	</ion-thumbnail>
					    	</div>
					    	<div class="elem-item">
						    	<ion-label>
									<div style="display: flex;">
										<p style="color: black; font-size: 1em; text-transform: uppercase;">
											<b>{{ subc.nombre }}</b>
										</p>
										<span class="catidad">{{ cantidadArreglo[itemObjKey] }}</span>
									</div>
									<p>{{ subc.descripcion }} </p> 
								</ion-label>
						    </div>
					    </ion-item>
					</div>
				</ion-list>
				<ion-list v-else>
					<ion-ripple-effect type="unbounded"></ion-ripple-effect>
					<ion-item  lines="none" class="item-servicios" style="padding-left: 2px; padding-right: 2px; margin-top: 2px; margin-bottom: 2px;">	
						<div class="elem-item">
							<ion-label>
								<p style="color: black; font-size: 0.8em;"><b>No tiene sub categorías disponibles</b></p>
							</ion-label>
						</div>
					</ion-item>
				</ion-list>
			</div>
			<div class="cuerpo" >
			    <ion-item-divider>
					<ion-label><h2>Servicios disponibles</h2></ion-label>
			    </ion-item-divider>
			    <ion-list v-if="existenServicios">
					<div v-for="serv in serviciosArreglo">
						<ion-ripple-effect type="unbounded"></ion-ripple-effect>
						<ion-item button @click="ModalDetalleServicio(serv)" lines="none" class="item-servicios" style="padding-left: 2px; padding-right: 2px; margin-top: 2px; margin-bottom: 2px;">	
					    	<div class="elem-item" style="padding-right: 2em;">
					    		<ion-thumbnail slot="start">
						        	<ion-img class="icon-serv" :src="categoria.imagen"></ion-img>
						    	</ion-thumbnail>
					    	</div>
					    	<div class="elem-item" style="padding-top: 0px;">
						    	<ion-label>
									<p style="color: black; font-size: 1em; text-transform: uppercase;">
										<b>{{ serv.nombre }}</b>
										<div style="position: absolute;font-size: 13px">{{serv.prestador.empresa}}</div>
										<div style="position: absolute;margin-top: 18px;font-weight: bold;">${{serv.valor}}</div>
										<div class="clasificacion-promedio"> 
											<label v-for="index in (5 - serv.prestador.valoracion_promedio)" class="estrella-label-sin" for="radio-valor-5">★</label>
											<label v-for="index in serv.prestador.valoracion_promedio" class="estrella-label" for="radio-valor-5">★</label>
										</div>
									</p>
								</ion-label>
						    </div>
					    </ion-item>
					</div>
				</ion-list>
				<ion-list v-else>
					<ion-ripple-effect type="unbounded"></ion-ripple-effect>
					<ion-item  lines="none" class="item-servicios" style="padding-left: 2px; padding-right: 2px; margin-top: 2px; margin-bottom: 2px;">	
						<div class="elem-item">
							<ion-label>
								<p style="color: black; font-size: 0.8em;"><b>No tiene servicios disponibles</b></p>
							</ion-label>
						</div>
					</ion-item>
				</ion-list>
			</div>
		</div>
	</ion-content>
</ion-page>
<!-- <ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-button class="btn-exit" @click="Back">
					<ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-buttons slot="start">
				<h5 class="titulo-head">Detalle servicio</h5>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content>
		<div class="contenido-home">
			<ion-row responsive-lg>
				<ion-col>
					<ion-card class="card-terminos">
						<ion-card-content>
							<ion-row>
								<ion-col size="12">
									<ion-label class="text-color-primario">
										<b>Servicio</b>
									</ion-label>
								</ion-col>
								<ion-col size="12">
									<ion-label>
										{{ servicio.nombre }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="12">
									<ion-label class="text-color-primario">
										<b>Descripción</b>
									</ion-label>
								</ion-col>
								<ion-col size="12">
									<ion-label>
										{{ servicio.descripcion }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="12" class="col-sep">
									<ion-label class="text-color-primario">
										<b>Categoria</b>
									</ion-label>
								</ion-col>
								<ion-col size="12">
									<ion-label>
										{{ servicio.categoria.nombre }}
									</ion-label>
								</ion-col>
							</ion-row>

							<ion-row>
								<ion-col size="12" class="col-sep">
									<ion-label class="text-color-primario">
										<b>Valor</b>
									</ion-label>
								</ion-col>
								<ion-col size="12">
									<ion-label>
										${{ servicio.valor }}
									</ion-label>
								</ion-col>
							</ion-row>
							 agregar horarios 
							<ion-card
								style="height: 130px; width: 100%; margin-left: auto; margin-right: auto; text-align: center;">
								<ion-card-header>
									<p style="font-size: 1.3em; text-align: center;">
										<ion-label>
											Disponibilidad
										</ion-label>
									</p>
								</ion-card-header>
								<ion-card-content>
									<ion-row style="font-size: 0.53em; border-bottom: groove;">
										<ion-col>
											<ion-label>Lun</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Mar</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Mié</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Jue</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Vie</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Sáb</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Dom</ion-label>
										</ion-col>
									</ion-row>
									<ion-row style="text-align: center;">
										<ion-col v-if="servicio.disponibilidad.includes('Lun')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Mar')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Mié')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Jue')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Vie')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Sáb')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Dom')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
									</ion-row>

									 <p class="cen-v">
										<ion-label>
											{{ servicio.disponibilidad }}
										</ion-label>
									</p> 	
								</ion-card-content>
							</ion-card>
						</ion-card-content>
					</ion-card>
				</ion-col>
			</ion-row>
		</div>
	</ion-content>
</ion-page> -->